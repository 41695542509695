import {jsPDF} from "jspdf";

/* Add this attribute to hide items from printing
* data-html2canvas-ignore="true"
* */

const printPDF = async function (isMember, callback) {
    const doc = new jsPDF({
        orientation: "p",
        format: "legal",
        unit: "px",
        hotfixes: ["px_scaling"]
    });

    const elementHandler = {
        '#ignorePDF': function (element) {
            return false;
        }
    }
    
    const blobToBase64 = async blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            }
        });
    }

    const application = document.getElementById('application');
    if (application.classList.contains('hidden')) {
        application.classList.remove('hidden');
    }
    
    const memberSsnInput = document.getElementById('memberSsn')
    if (!memberSsnInput.classList.contains('hidden')) {
        memberSsnInput.classList.add('hidden');
    }
    
    const ssnInput = document.getElementById('ssn');
    if (ssnInput.type === 'hidden') {
        ssnInput.type = 'text';
        ssnInput.classList.add("form-control")
    }

    const coverage = document.getElementById('coverage');
    if (coverage.classList.contains('hidden')) {
        coverage.classList.remove('hidden');
    }

    let currentBenefits;
    let availableBenefits;
    let selectBenefits;
    let paymentOptions;

    if (isMember) {
        currentBenefits = document.getElementById('currentBenefits');
        if (currentBenefits.classList.contains('hidden')) {
            currentBenefits.classList.remove('hidden');
        }

        availableBenefits = document.getElementById('availableBenefits');
        if (availableBenefits.classList.contains('hidden')) {
            availableBenefits.classList.remove('hidden');
        }
    } else {
        selectBenefits = document.getElementById('selectBenefits');
        if (selectBenefits.classList.contains('hidden')) {
            selectBenefits.classList.remove('hidden');
        }

        paymentOptions = document.getElementById('paymentOptions');
        if (paymentOptions.classList.contains('hidden')) {
            paymentOptions.classList.remove('hidden');
        }
    }

    const signature = document.getElementById('signature');
    if (signature.classList.contains('hidden')) {
        signature.classList.remove('hidden');
    }

    const confirmation = document.getElementById('confirmation');
    if (confirmation.classList.contains('hidden')) {
        confirmation.classList.remove('hidden');
    }

    const complete = document.getElementById('finished');
    complete.classList.add('hidden');
    
    const body = document.getElementById("content");

    let width = 776;
    doc.html(body, {
        autoPaging: 'slice',
        width: width,
        windowWidth: width,
        margin:20,
        image: 'png',
        filename: 'Complete Enrollment Application.pdf',
        html2canvas: {windowWidth: width},
        callback: async function (doc) {
            application.classList.add('hidden');
            coverage.classList.add('hidden');
            if (isMember) {
                currentBenefits.classList.remove('hidden');
                availableBenefits.classList.remove('hidden');
            } else {
                selectBenefits.classList.remove('hidden');
                paymentOptions.classList.remove('hidden');
            }
            signature.classList.add('hidden');
            confirmation.classList.add('hidden');
            
            // Create the file
            let pdf = doc.output('blob');   
            const base64String = await blobToBase64(pdf);
            callback(base64String);
        },
        elementHandler: elementHandler
    });
}

export default printPDF;