import React from "react";
import EffectiveDate from "./EffectiveDate";
import {TableBody} from "./TableBody";
import OverrideReason from "./OverrideReason";
import moment from "moment";

export function SelectBenefits(props) {
    const currentMonthSelected = props.effectiveMonth === moment().format('YYYY-MM');
    return (
        <section className={"print " + ((props.display) ? '' : 'hidden')} id="selectBenefits">
            <div className="row">
                <div className="col">
                    <h5 className="brand-color-red mb-3">Section 1 | Benefits</h5>
                    <hr/>
                    <p className="mb-0">Select the benefit coverage option(s) <small
                        className="text-muted">(*must select at least one)</small></p>
                    <small className="text-muted">View more information about each benefit at <a
                        href={"https://completebenefitplans.com/tribute-complete-benefits/"} target="_blank"
                        rel="noopener noreferrer">
                        Complete Benefits</a></small>
                </div>
            </div>
            <div className="row mt-4 table-tall">
                <div className="col">
                    <table className="table table-sm">
                        <thead>
                        <tr>
                            <th className="no-top-border" id="selectBenefit">Benefit</th>
                            <th className="no-top-border">Premium</th>
                        </tr>
                        </thead>

                        <TableBody name="planBenefits"
                                   value={props.planBenefits}
                                   selectedBenefits={props.selectedBenefits}
                                   onChange={props.handleBenefitChange} 
                                   required={"required"}/>

                    </table>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col">
                    <label htmlFor={"monthlyPremium"} className="semi-bold">Total Monthly Premium </label>
                </div>
                <div className="col form-group form-inline">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <input type="text"
                               className="form-control"
                               id="monthlyPremium"
                               name="monthlyPremium"
                               value={props.monthlyPremium + " /month"}
                               readOnly/>
                    </div>
                </div>
            </div>
            <EffectiveDate
                minEffectiveMonth={props.minEffectiveMonth}
                effectiveMonth={props.effectiveMonth}
                handleEffectiveMonthChange={props.handleEffectiveMonthChange}
            />
            {currentMonthSelected &&
                <OverrideReason
                    itemList={props.effectiveDateOverrideReasonList}
                    selectedItem={props.overrideReasonId}
                    handleOverrideReasonChange={props.handleOverrideReasonChange} 
                    handleOverrideReasonOtherChange={props.handleOverrideReasonOtherChange}/>}
        </section>);
}