import React from "react";
import {SelectBenefits} from "./SelectBenefits";
import {CurrentBenefits} from "./CurrentBenefits";
import {PaymentOptions} from "./PaymentOptions";
import {AvailableBenefits} from "./AvailableBenefits";
import {SpinnerBasic} from "./Spinner-basic";
import {NavFooter} from "./NavFooter";
import '../custom.css';
export function Coverage(props) {
    const display = props.step === 2;
    return (
        <div className={"container break-before print " + ((display) ? '' : 'hidden')} id="coverage" >
            <form method="post" id="formCoverage" name="formCoverage" onSubmit={props.onSubmit}>

                <div className="row mt-5">
                    <div className="col">
                        <h2 className="brand-color-lightgray">Coverage</h2>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12 col-md-6">
                        {
                            props.loading
                                ? <SpinnerBasic/>
                                : props.isMember
                                    ? <CurrentBenefits memberBenefits={props.memberBenefits}
                                                       currentMonthlyPremium={props.currentMonthlyPremium}
                                                       selectedBenefits={props.selectedBenefits}
                                                       display={display}/>
                                    : <SelectBenefits planBenefits={props.planBenefits}
                                                      display={display}
                                                      effectiveMonth={props.effectiveMonth}
                                                      handleEffectiveMonthChange={props.handleEffectiveMonthChange}
                                                      monthlyPremium={props.monthlyPremium}
                                                      selectedBenefits={props.selectedBenefits}
                                                      handleBenefitChange={props.handleBenefitChange}
                                                      minEffectiveMonth={props.minEffectiveMonth}
                                                      {...props}
                                    />
                        }
                    </div>
                    <div className="col-sm-12 col-md-6 pl-md-5">
                        {
                            props.loading
                                ? <SpinnerBasic/>
                                : props.isMember
                                    ? <AvailableBenefits availableBenefits={props.availableBenefits}
                                                         monthlyPremium={props.monthlyPremium}
                                                         currentMonthlyPremium={props.currentMonthlyPremium}
                                                         effectiveMonth={props.effectiveMonth}
                                                         handleEffectiveMonthChange={props.handleEffectiveMonthChange}
                                                         handleBenefitChange={props.handleBenefitChange}
                                                         selectedBenefits={props.selectedBenefits}
                                                         display={display} 
                                                         minEffectiveMonth={props.minEffectiveMonth}/>
                                    : <PaymentOptions display={display}
                                                      premiumPaymentMethodId={props.premiumPaymentMethodId}
                                                      mailingAddressIsFacilityOrPoa={props.mailingAddressIsFacilityOrPoa}
                                                      handlePremiumPaymentMethodIdChange={props.handlePremiumPaymentMethodIdChange}
                                                      handleMailingAddressIsFacilityOrPoaChange={props.handleMailingAddressIsFacilityOrPoaChange}
                                    />
                        }
                    </div>
                </div>
                {props.availableBenefits.length > 0 &&
                <NavFooter step={props.step}
                           lastStep={props.lastStep}
                           display={props.step === 2} 
                           onChange={props.onChange} />
                 }
            </form>
        </div>
    )
}