import React from 'react';
import {MemberIdentification} from "./MemberIdentification";
import logo from "../assets/Complete-Care-Logo.png";
import {NavFooter} from "./NavFooter";

export function Application(props) {
    const display = props.step === 1 ? '' : 'hidden';
    return (
        <div className={`container ${display} print`} id="application">
            <form method="post" id="formMember" name="formMember" onSubmit={props.onSubmit}>

                <div className="row mt-5 hidden print">
                    <div className="col">
                        <img src={logo} alt=""/>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col">
                        <h2 className="brand-color-lightgray">Application for Individual Coverage</h2>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-sm-12 mt-3">
                        <p>This application is for a product provided by Arkansas Superior Select, Inc.
                            ("ASSI", "we", "our" or "us") in which Policyholders enroll in Complete Personal
                            and Direct Care, authorizing providers to perform various covered services.</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <h5 className="brand-color-lightgray mb-0">Policyholder Information</h5>
                        <span className="required small no-print">* Required fields</span>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <MemberIdentification facilities={props.facilities} member={props.member} onMemberChange={props.onMemberChange} {...props}/>
                    </div>
                </div>
                <NavFooter step={props.step}
                           lastStep={props.lastStep}
                           display={props.step === 1} /> 
            </form>
        </div>

    )
}