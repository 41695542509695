import React, {forwardRef} from 'react';
import Disenrollment from "../Disenrollment";
import TransferApplication from "../../transfer/TransferApplication";
import logo from "../../assets/Complete-Care-Logo-sm.png";
import {NavbarBrand} from "reactstrap";
import MemberNumber from "./MemberNumber";

const PrintApplication = forwardRef((props, ref) => (
    <div ref={ref} id={"PrintApplication"} className={"container print hidden"}>
        <div className={"row mb-4"}>
            <div className={"col-sm-6"}>
                <NavbarBrand className="logo">
                    <img src={logo} alt="" className={"print"}/>
                </NavbarBrand>
            </div>
            <div className={"col-sm-5 text-right print"}>
                <h5>{props.activeTab === 0 ? "Disenrollment" : "Transfer"} Form</h5>
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-sm-4">
                <MemberNumber {...props} />
            </div>
        </div>
        <div className={props.activeTab === 0 ? "print" : "no-print hidden"}>
            <Disenrollment {...props}/>
        </div>
        <div className={props.activeTab === 1 ? "print" : "no-print hidden"}>
            <TransferApplication {...props}/>
        </div>
        <div>
            <span className={"text-muted"}><small>TC-DISENROLL-2023</small></span>
        </div>
    </div>
));

export default PrintApplication;