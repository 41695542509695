import React, {useEffect, useState} from 'react';
import {Home} from './components/Home';
import './custom.css'
import {SpinnerBasic} from "./components/Spinner-basic";
import Index from "./disenrollment/Index";
import moment from "moment";
import {Modal, ModalBody, ModalFooter, ModalHeader, Button} from "reactstrap";

export default function App(props) {
    const [step, setStep] = useState(1);
    const [lastStep, setLastStep] = useState(0);
    const [loading, setLoading] = useState(true);
    const [facilities, setFacilities] = useState([]);
    const [planBenefits, setPlanBenefits] = useState([]);
    const [availableBenefits, setAvailableBenefits] = useState([]);
    const [member, setMember] = useState({});
    const [isMember, setIsMember] = useState(false);
    const [memberBenefits, setMemberBenefits] = useState([]);
    const [monthlyPremium, setMonthlyPremium] = useState(0);
    const [premiumPaymentMethodId, setPremiumPaymentMethodId] = useState(0);
    const [mailingAddressIsFacilityOrPoa, setMailingAddressIsFacilityOrPoa] = useState('');
    const [effectiveMonth, setEffectiveMonth] = useState(nextMonth());
    const [dateOfTransfer, setDateOfTransfer] = useState('');
    const [digitalSignature, setDigitalSignature] = useState('');
    const [digitalSignatureConfirmation, setDigitalSignatureConfirmation] = useState('');
    const [signatureDate, setSignatureDate] = useState('');
    const [currentMonthlyPremium, setCurrentMonthlyPremium] = useState(0);
    const [required, setRequired] = useState('');
    const [maPlanId, setMaPlanId] = useState(0);
    const [authorizedRepresentative, setAuthorizedRepresentative] = useState({
        lastName: "",
        firstName: "",
        middleInitial: "",
        address1: "",
        city: "",
        stateOrProvince: "",
        zipPostalCode: "",
        phoneNumber: "",
        relationshipToMember: ""
    });
    const [selectedBenefits, setSelectedBenefits] = useState([]);
    const [selectedFacility, setSelectedFacility] = useState({
        id: 0,
        location: {
            address1: '',
            address2: null,
            address3: null,
            city: '',
            country: null,
            emailAddress: null,
            stateOrProvince: '',
            zipPostalCode: ''
        },
        name: '',
        phone: ''
    });
    const [signatureConfirmationCheck, setSignatureConfirmationCheck] = useState(false);
    const [formAcceptCheck, setFormAcceptCheck] = useState(false);
    const [showAuthRep, setShowAuthRep] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(false);
    const [memberNotFound, setMemberNotFound] = useState(false);
    const [postError, setPostError] = useState(false);
    const [transferApp, setTransferApp] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [disenrollmentReason, setDisenrollmentReason] = useState('');
    const [disenrollmentReasons, setDisenrollmentReasons] = useState([]);
    const [newFacilityId, setNewFacilityId] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirmationNumber, setConfirmationNumber] = useState('');
    const [enableForm, setEnableForm] = useState(false);
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState('');
    const [effectiveDateOverrideReasonId, setEffectiveDateOverrideReasonId] = useState(null);
    const [effectiveDateOverrideReasonOther, setEffectiveDateOverrideReasonOther] = useState(null);
    const [effectiveDateOverrideReasonList, setEffectiveDateOverrideReasonList] = useState([]);
    const effectiveDateOverrideReasonOtherId = effectiveDateOverrideReasonList.find(x => x.AdditionTextRequired === true);
    const [overrideReasonDeadline, setOverrideReasonDeadline] = useState(0);
    const currentMonthAllowed = moment().date() <= overrideReasonDeadline;
    const minEffectiveMonth = moment().startOf('month').add(currentMonthAllowed ? 0 : 1, 'M').format('YYYY-MM');
    const [modal, setModal] = useState(false);
    
    const toggleErrorModal = () => {setModal(!modal)}
    
    function handleOverrideReasonChange({target}) {
        const overrideReasonId = target.value;
        setEffectiveDateOverrideReasonId(overrideReasonId);
        if (overrideReasonId !== effectiveDateOverrideReasonOtherId) {
            setEffectiveDateOverrideReasonOther('');
        }
    }

    function handleOverrideReasonOtherChange({target}) {
        const value = target.value;
        setEffectiveDateOverrideReasonOther(value);
    }

    const handlePremiumPaymentMethodIdChange = ({target}) => {
        const id = target.value;
        if (id !== "2") {
            setMailingAddressIsFacilityOrPoa('');
        }
        setPremiumPaymentMethodId(id);
    }

    const handleMailingAddressIsFacilityOrPoaChange = ({target}) => {
        const value = target.value;
        setMailingAddressIsFacilityOrPoa(value);
    }

    const handleEffectiveMonthChange = ({target}) => {
        const value = target.value;
        setEffectiveMonth(value);
        if (value !== moment().format('YYYY-MM')) {
            setEffectiveDateOverrideReasonId('0');
            setEffectiveDateOverrideReasonOther('');
        }
    }

    const handleDateOfTransferChange = ({target}) => {
        const value = target.value;
        setDateOfTransfer(value);
    }

    const handleDigitalSignatureChange = ({target}) => {
        const value = target.value;
        setDigitalSignature(value);
    }

    const handleDigitalSignatureConfirmationChange = ({target}) => {
        const value = target.value;
        setDigitalSignatureConfirmation(value);
    }

    const handleSignatureDateChange = ({target}) => {
        const value = target.value;
        setSignatureDate(value);
    }

    const handleAuthorizedRepChange = ({target}) => {

        const key = target.name;
        if (key === "relationshipToMember") {
            if (target.checked === true && target.id !== 'Member') {
                setShowAuthRep(true);
            } else {
                setShowAuthRep(false);
                resetAuthorizedRepresentative(target.value);
                return;
            }
        }
        const value = target.value;
        setAuthorizedRepresentative({...authorizedRepresentative, [key]: value})
    }

    const handleSignatureConfirmationCheckChange = ({target}) => {
        const checked = target.checked;
        setSignatureConfirmationCheck(checked);
    }

    const handleFormAcceptCheckChange = ({target}) => {
        const checked = target.checked;
        setFormAcceptCheck(checked);
    }

    const handleResetFormAccept = () => {
        setFormAcceptCheck(false);
    }

    const handleResetSignature = () => {
        setDigitalSignature('');
        setDigitalSignatureConfirmation('');
        setSignatureDate('');
    }

    const handleMemberChange = ({target}) => {
        const name = target.name;
        const value = target.value;
        const valueOnly = value.replace(/\D/gi, '');
        if (name === 'ssn') {
            setMember({...member, [name]: valueOnly});
        } else {
            setMember({...member, [name]: value});
        }
    }

    const handleRecaptchaChange = ({target}) => {
        const value = target.checked;
        setRecaptchaValue(value);
    }

    const handleNavSelect = (e) => {
        const id = e.target.id;
        setActiveTab(id === 'nav-disenroll-tab' ? 0 : 1);
    }

    const handleDisenrollmentSignature = ({target}) => {
        const name = target.name;
        if (name === 'signature') {
            const value = target.value;
            setDigitalSignature(value);
        }
        if (name === 'relationshipToMember') {
            const text = target.options[target.selectedIndex].innerText
            setAuthorizedRepresentative({
                lastName: "",
                firstName: "",
                middleInitial: "",
                address1: "",
                city: "",
                stateOrProvince: "",
                zipPostalCode: "",
                phoneNumber: "",
                relationshipToMember: text || ""
            })
        }
        if (name === 'effectiveDate') {
            const value = target.value;
            setSignatureDate(value);
        }
    }

    const handleDisenrollmentReason = ({target}) => {
        const reason = target.value;
        setDisenrollmentReason(reason);
    }

    const handleNewFacilityId = ({target}) => {
        const value = target.value;
        setNewFacilityId(value);
    }

    const handleShowConfirmation = ({target}) => {
        const value = target.value;
        setShowConfirmation(value);
    }

    function nextMonth() {
        return moment().add(1, 'months').format('YYYY-MM');
    }

    function formatDate(value) {
        if (value === undefined) return '';
        return moment(value).format('MM/DD/YYYY');
    }

    function handleSelectedAgent({target}) {
        const value = target.value;
        setSelectedAgent(value);
    }

    const state = {
        'step': step,
        'lastStep': lastStep,
        'loading': loading,
        'facilities': facilities,
        'planBenefits': planBenefits,
        'availableBenefits': availableBenefits,
        'member': member,
        'isMember': isMember,
        'memberBenefits': memberBenefits,
        'monthlyPremium': monthlyPremium,
        'currentMonthlyPremium': currentMonthlyPremium,
        'handleBenefitChange': handleBenefitChange,
        'required': required,
        'maPlanId': maPlanId,
        'authorizedRepresentative': authorizedRepresentative,
        'handleAuthorizedRepChange': handleAuthorizedRepChange,
        'selectedBenefits': selectedBenefits,
        'selectedFacility': selectedFacility,
        'premiumPaymentMethodId': premiumPaymentMethodId,
        'handlePremiumPaymentMethodIdChange': handlePremiumPaymentMethodIdChange,
        'mailingAddressIsFacilityOrPoa': mailingAddressIsFacilityOrPoa,
        'handleMailingAddressIsFacilityOrPoaChange': handleMailingAddressIsFacilityOrPoaChange,
        "effectiveMonth": effectiveMonth,
        "minEffectiveMonth": minEffectiveMonth,
        "dateOfTransfer": dateOfTransfer,
        "handleEffectiveMonthChange": handleEffectiveMonthChange,
        "handleDateOfTransferChange": handleDateOfTransferChange,
        "digitalSignature": digitalSignature,
        "handleDigitalSignatureChange": handleDigitalSignatureChange,
        "digitalSignatureConfirmation": digitalSignatureConfirmation,
        "handleDigitalSignatureConfirmationChange": handleDigitalSignatureConfirmationChange,
        "signatureDate": signatureDate,
        "handleSignatureDateChange": handleSignatureDateChange,
        "signatureConfirmationCheck": signatureConfirmationCheck,
        "handleSignatureConfirmationCheckChange": handleSignatureConfirmationCheckChange,
        "formAcceptCheck": formAcceptCheck,
        "handleFormAcceptCheckChange": handleFormAcceptCheckChange,
        "onMemberChange": handleMemberChange,
        "handleResetFormAccept": handleResetFormAccept,
        "showAuthRep": showAuthRep,
        "recaptchaValue": recaptchaValue,
        "handleRecaptchaChange": handleRecaptchaChange,
        "resetSignature": handleResetSignature,
        'onDisenrollmentBenefitChange': handleDisenrollmentBenefitChange,
        'memberNotFound': memberNotFound,
        'postError': postError,
        'transferApp': transferApp,
        'handleNavSelect': handleNavSelect,
        'activeTab': activeTab,
        'handleDisenrollmentSignature': handleDisenrollmentSignature,
        'disenrollmentReason': disenrollmentReason,
        'handleDisenrollmentReason': handleDisenrollmentReason,
        'disenrollmentReasons': disenrollmentReasons,
        'newFacilityId': newFacilityId,
        'handleNewFacilityId': handleNewFacilityId,
        'showConfirmation': showConfirmation,
        'handleShowConfirmation': handleShowConfirmation,
        'confirmationNumber': confirmationNumber,
        'formatDate': formatDate,
        'enableForm': enableForm,
        'agents': agents,
        'selectedAgent': selectedAgent,
        'handleSelectedAgent': handleSelectedAgent,
        'effectiveDateOverrideReasonList': effectiveDateOverrideReasonList,
        'overrideReasonId': effectiveDateOverrideReasonId,
        'handleOverrideReasonChange': handleOverrideReasonChange,
        'overrideReasonOther': effectiveDateOverrideReasonOther,
        'handleOverrideReasonOtherChange': handleOverrideReasonOtherChange,
    }

    useEffect(() => {
        setLastStep(5);
        fetchContractData().then(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setAvailableBenefits(filterAvailableBenefits());
    }, [memberBenefits, planBenefits])

    async function fetchContractData() {
        await fetch("api/OnlineEnrollment")
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(data => {
                    setMaPlanId(data.maPlanId);
                    setFacilities(data.facilities);
                    setDisenrollmentReasons(data.disenrollmentReasons);
                    setAgents(data.agents);
                    setEffectiveDateOverrideReasonList(data.overrideReasons);
                    setOverrideReasonDeadline(data.overrideReasonDeadline);
                }
            )
            .catch((error) => {
                console.error('fetchContractData error', error);
            })

    }

    async function fetchMemberBenefits(model) {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(model)
        }
        let response = await fetch("api/OnlineEnrollment/GetMemberBenefits/", requestOptions);

        return await response.json();
    }

    async function fetchProviderBenefits(model) {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(model)
        }
        let response = await fetch("api/OnlineEnrollment/GetProviderBenefits/", requestOptions);
        if (response.status === 400) {
            throw Error(response.status.toString());
        }
        if (response.status === 500) {

        }
        return await response.json();
    }

    async function fetchMemberData(model) {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(model)
        }
        try {
            let response = await fetch("api/OnlineEnrollment/MemberData/", requestOptions);
            if (response.ok) {
                return await response.json();
            } else {
                setMemberNotFound(true);
                console.log(Error(response.statusText));
            }
        } catch (error) {
            console.log('fetchMemberData', error);
        } finally {
            setLoading(false);
        }
    }

    const resetMember = () => {
        setMember({
            lastName: null,
            firstName: null,
            dateOfBirth: null,
            facilityId: null,
            memberNumber: null,
        })
    }

    const handleMemberSearch = () => {
        setMemberBenefits([]);
        setMemberNotFound(false);
        fetchMemberData({
            memberNumber: member.memberNumber,
            lastName: member.lastName,
            dateOfBirth: member.dateOfBirth
        })
            .then((data) => {
                    data.member.dateOfBirth = formatDate(data.member.dateOfBirth);
                    setMember(data.member);
                    setMemberBenefits(data.planBenefits);
                    setIsMember(true);
                    const num = String(data.member.confirmationNumber).padStart(10, '0');
                    setConfirmationNumber(num);
                    setEnableForm(typeof data.member.dateOfBirth !== "undefined");
                }
            )
            .catch(error => {
                console.warn('handleMemberSearch error', error);
            })
            .finally(() => {
                    setLoading(false);
                }
            );
        return false;
    }

    async function postApplication(model) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        }

        let response = await fetch("api/OnlineEnrollment/PostApplication/", requestOptions);
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return await response.json();
    }

    async function postDisenrollment(model) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model)
        }

        let response = await fetch("api/OnlineEnrollment/Disenrollment/", requestOptions);
        return response.statusText;
    }

    async function postTransfer(model) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model)
        }

        let response = await fetch("api/OnlineEnrollment/Transfer/", requestOptions);
        return response.statusText;
    }

    async function handleMemberSubmit(member) {
        const facilityId = getFacilityById(member.facilityId);
        if (facilityId !== undefined) {
            setSelectedFacility(facilityId);
        }

        setMember({...member});
        fetchMemberBenefits(member)
            .then((data) => {
                    console.log('fetchMemberBenefits begin', data);
                    if (Array.isArray(data)) {
                        setMemberBenefits(data);
                        setIsMember(true);
                        setCurrentMonthlyPremium(sumArray(data));
                    }
                }
            ).then(() => {
            getProviderBenefits();
        })
            .catch(error => {
                error.message === 'Invalid Member Information' ? toggleErrorModal() : handleStepChange(step + 1);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    
    async function getProviderBenefits() {
        fetchProviderBenefits(member)
            .then((data) => {
                    if (Object.keys(data).length > 0) {
                        setPlanBenefits(data);
                    }
                    handleStepChange(step + 1);
                }
            )
            .catch(error => {
                error.message === '400' ? toggleErrorModal() : handleStepChange(step + 1);
            });
    }

    async function handleFormSubmit(model) {
        postApplication(model)
            .then(() => {
            })
            .catch(error => {
                console.warn('handleFormSubmit error', error);
            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                    handleStepChange(step + 1);
                }, 3000)
            });
    }

    async function handleDisenrollment(model) {
        setLoading(true);
        postDisenrollment(model)
            .then((resp) => {
                setShowConfirmation(true);
                setPostError(false);
            })
            .catch(error => {
                setPostError(true);
                resetMember();
                console.warn('handleDisenrollment error', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    async function handleTransfer(model) {
        postTransfer(model)
            .then(() => {
                setShowConfirmation(true);
                setPostError(false);
            })
            .catch(error => {
                setPostError(true);
                resetMember();
                console.warn('handleTransfer error', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleBenefitChange({target}) {
        const premium = target.value;
        const checked = target.checked;
        const name = target.name;
        let total = monthlyPremium;
        if (checked) {
            total += parseInt(premium);
            setSelectedBenefits([...selectedBenefits, name]);
        } else {
            total -= parseInt(premium);
            setSelectedBenefits((prev) => prev.filter(b => b !== name));
        }
        setMonthlyPremium(total);
    }

    function handleDisenrollmentBenefitChange({target}) {
        const value = target.value;
        const checked = target.checked;
        let newSelectedBenefits = [...selectedBenefits, value];
        if (checked === true) {
            setSelectedBenefits(newSelectedBenefits);
        } else {
            setSelectedBenefits((prev) => prev.filter(b => b !== value));
        }
    }

    function handleStepChange(step) {
        if (step === 1) {
            setStep(() => {
                return step + 1;
            });
        }
        setStep(() => {
            return step;
        });
    }

    function handleSignatureSubmit(model) {
        setAuthorizedRepresentative(model);
        handleStepChange(step + 1);
    }

    function handleCoverageSubmit(model) {
        setSelectedBenefits(model);
        handleStepChange(step + 1);
    }

    function handleLoadingChange(isLoading) {
        setLoading(isLoading);
    }

    function filterAvailableBenefits() {
        let availableBenefits = [];
        if (propExists(planBenefits)) {
            availableBenefits = planBenefits.filter(pb => !memberBenefits.find(mb => mb.id === pb.id));
        }
        return availableBenefits;
    }

    function sumArray(arr) {
        const reducer = (previousValue, currentValue) => previousValue + currentValue.premium;
        return arr.reduce(reducer, 0);
    }

    function propExists(obj) {
        return Object.keys(obj).length > 0;
    }

    function getFacilityById(facilityId) {
        return facilities.find(({id}) => id === parseInt(facilityId, 10));
    }

    function getValueFromQuerystring(param) {
        let search = window.location.search.toLowerCase();
        let params = new URLSearchParams(search);
        return params.get(param);   // Returns null if not present
    }

    function resetAuthorizedRepresentative(value) {
        setAuthorizedRepresentative({
            lastName: "",
            firstName: "",
            middleInitial: "",
            address1: "",
            city: "",
            stateOrProvince: "",
            zipPostalCode: "",
            phoneNumber: "",
            relationshipToMember: value || ""
        });
    }

    function isDisenrollment() {
        const value = getValueFromQuerystring('type');
        return (value != null && value.substring(0, 9) === 'disenroll');
    }

    async function handleMemberDisenrollmentSubmit(member) {
        const facility = getFacilityById(member.facility);
        if (facility !== undefined) {
            setSelectedFacility(facility);
        }
        setMember({...member});
        fetchMemberBenefits(member)
            .then((data) => {
                    if (Object.keys(data).length > 0) {
                        setAvailableBenefits(filterAvailableBenefits());
                        setMemberBenefits(data);
                        setIsMember(true);
                        setCurrentMonthlyPremium(sumArray(data));
                    }
                }
            )
            .catch(error => {
                console.log('handleMemberDisenrollmentSubmit error', error);
                toggleErrorModal();
            })
            .finally(() => {
                    setLoading(false);
                    return false;
                }
            );
    }

    function handleDisenrollmentBenefitsSubmit(model) {
        setSelectedBenefits(model);
        alert('This would remove the benefit and update the list.');
    }

    return (
        loading
            ? <SpinnerBasic/>
            : (isDisenrollment()
                    ? <Index {...state}
                             onChange={handleStepChange}
                             onMemberSubmit={handleMemberDisenrollmentSubmit}
                             onSignatureSubmit={handleSignatureSubmit}
                             onCoverageSubmit={handleDisenrollmentBenefitsSubmit}
                             onLoading={handleLoadingChange}
                             member={member}
                             onMemberSearch={handleMemberSearch}
                             onDisenrollment={handleDisenrollment}
                             onTransfer={handleTransfer}
                    />
                    : <>
                        <Home {...state}
                              onChange={handleStepChange}
                              onMemberSubmit={handleMemberSubmit}
                              onFormSubmit={handleFormSubmit}
                              onSignatureSubmit={handleSignatureSubmit}
                              onCoverageSubmit={handleCoverageSubmit}
                              onLoading={handleLoadingChange}
                              member={member}/>
                        <Modal isOpen={modal} toggle={toggleErrorModal} {...props}>
                            <ModalHeader toggle={toggleErrorModal}>Attention</ModalHeader>
                            <ModalBody>One or more fields do not match our records.</ModalBody>
                            <ModalFooter><Button size={"sm"} onClick={toggleErrorModal}>Close</Button></ModalFooter>
                        </Modal>
                    </>
            )

    );
}
