import React, {useEffect, useState} from "react";
import {TextInput} from "../components/TextInput";

function TransferApplication(props) {
    const [facility, setFacility] = useState('');
    const [facilityId, setFacilityId] = useState(0);
    const [hdnMemberNumber, setHdnMemberNumber] = useState('');

    useEffect(() => {
        if (!props.member.facilityId) {
            setFacility("");
            setFacilityId(0);
        } else {
            !props.memberNotFound && setFacility(props.facilities.find(x => x.id === props.member.facilityId).name);
            !props.memberNotFound && setFacilityId(props.member.facilityId);
        }
    }, [props.member, props.facilities, props.memberNotFound])

    useEffect(() => {
        setHdnMemberNumber(props.member.memberNumber);
    },[props.member.memberNumber])

    return (
        <>
            <div className={props.activeTab === 1 ? "print" : "no-print"} id="TransferApplication">
                <form method="post" id="formTransfer" name="formTransfer" onSubmit={props.onTransfer}>
                    <input type={"hidden"}
                           id={"hdnMemberNumber"}
                           name={"hdnMemberNumber"}
                           value={hdnMemberNumber || ''}
                    />
                    <input type={"hidden"}
                           id={"hdnFacilityId"}
                           name={"hdnFacilityId"}
                           value={facilityId || ''}
                    />
                    <div className={"tab-contents"} style={{paddingLeft: '26px', marginLeft: '-15px', marginRight: '-15px'}}>
                        <div className="form-row">
                            <div className="form-group col-sm-12">
                                <label htmlFor="newFacilityId">New Facility</label>
                                <select id="newFacilityId"
                                        name="newFacilityId"
                                        className="form-control"
                                        required="required"
                                        aria-label="New Facility"
                                        value={props.newFacilityId}
                                        onChange={props.handleNewFacilityId}>
                                    <option key="0" value="">&nbsp;</option>
                                    {
                                        props.facilities.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                                    }
                                </select>
                                <span className={"small text-muted"} style={{paddingLeft: "6px"}}>If new facility not listed, use Disenrollment</span>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-sm-4">
                                <TextInput label={"Signature"}
                                           required={"required"}
                                           name={"signature"}
                                           onChange={props.handleDisenrollmentSignature}
                                           value={props.digitalSignature}
                                />
                            </div>
                            <div className="form-group col-sm-4">
                                <label htmlFor="relationshipToMember">Relationship</label>
                                <select id="relationshipToMember"
                                        name="relationshipToMember"
                                        className="form-control"
                                        required="required"
                                        aria-label="Relationship"
                                        value={props.authorizedRepresentative.relationshipToMember}
                                        onChange={props.handleAuthorizedRepChange}>
                                    <option key="0" value="">&nbsp;</option>
                                    <option key="1" value="1">Facility Representative</option>
                                    <option key="2" value="2">Member</option>
                                    <option key="3" value="3">Plan Representative</option>
                                    <option key="4" value="4">POA</option>
                                    <option key="5" value="5">Responsible Party</option>
                                </select>
                            </div>
                            <div className="form-group col-sm-4">
                                <TextInput name={"effectiveDate"}
                                           label={"Date of Transfer"}
                                           required={"required"}
                                           type={"date"}
                                           value={props.dateOfTransfer} onChange={props.handleDateOfTransferChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-row"
                         style={{backgroundColor: "#dcdcdc30", marginLeft: "-15px", marginRight: "-15px"}}>
                        <div className="form-group col-sm-12 text-center mt-4">
                            <button id={"disenrollVerify"} type={"submit"}
                                    className={"btn btn-danger no-print"}>Transfer
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <iframe id={"TransferApplicationPrintArea"} title={"print area"}
                    style={{height: '0px', width: '0px', position: 'absolute', left: '-2rem'}}>
            </iframe>
        </>
    );
}

export default TransferApplication;