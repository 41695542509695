import React from 'react';
import {Container, Navbar, NavbarBrand} from 'reactstrap';
import {Link} from 'react-router-dom';
import '../../custom.css';
import logo from '../../assets/Complete-Care-Logo.png';

export default function Header(props) {
    return (
        <header style={props.style}>
            <Navbar className="ng-white border-bottom box-shadow mb-3 header-container" light>
                <Container>
                    <NavbarBrand tag={Link} to="/" className="logo">
                        <img src={logo} alt="" className={"no-print"}/>
                        <img src={logo} alt="" className={"print"} style={{display: "none"}}/>
                    </NavbarBrand>
                    <h5>Disenrollment / Transfer Form</h5>
                </Container>
            </Navbar>
        </header>
    );
}
