import React from "react";
import {TableBody} from "./TableBody";

export function CurrentBenefits(props) {
    return (
        <section className={"print " + ((props.display) ? '' : 'hidden')} id="currentBenefits">
            <div className="row">
                <div className="col">
                    <h5 className="brand-color-red mb-3">Section 1 | Current Benefits</h5>
                    <hr/>
                    <p className="mb-0">Member's existing benefits on record.</p>
                    <small className="text-muted brand-color-red">View more information about each benefit at <a
                        href={"https://completebenefitplans.com/tribute-complete-benefits/"} target="_blank"
                        rel="noopener noreferrer">
                        Complete Benefits</a></small>
                </div>
            </div>
            <div className="row mt-3 table-tall">
                <div className="col">
                    <table className="table table-sm">
                        <thead>
                        <tr>
                            <th className="no-top-border">Current benefit(s)</th>
                            <th className="no-top-border">Premium</th>
                        </tr>
                        </thead>

                        <TableBody value={props.memberBenefits} checkbox="false"/>

                    </table>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label className="semi-bold">Current monthly premium:</label>
                </div>
                <div className="col">
                    <p className="pl-3">
                        ${props.currentMonthlyPremium}/month
                    </p>
                </div>
            </div>
        </section>);
}