import React from 'react';
import {TableRow} from "./TableRow";

export function TableBody(props) {
    const message = "Please fill out the required field.";

    function validate(event) {
        setValidation();
        props.onChange(event);
    }
    
    function setValidation(){
        if (props.required === 'required') {
            const nodeList = document
                .getElementById('formCoverage')
                .querySelectorAll('input[type="checkbox"]');
          
            if (nodeList.length > 0) {
                const checkBoxes = [...nodeList];
                const validationRow = checkBoxes[0];
                if (checkBoxes.some(x => x.checked)) {
                    validationRow.setCustomValidity('');
                } else {
                    validationRow.setCustomValidity(message);
                }
            }
        }
    }

    function content() {
        try {
            return <tbody id="name">{
                props.value
                    .map((value, index) => <TableRow description={value.description}
                                                     id={value.id}
                                                     name={props.name}
                                                     premium={value.premium}
                                                     key={index}
                                                     selectedBenefits={props.selectedBenefits}
                                                     checkbox={props.checkbox}
                                                     onInput={validate} 
                                                     setValidation={setValidation}
                    />)
            }</tbody>
        } catch (e) {
            return <tbody>
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            </tbody>;
        }
    }

    return (content());
}    
