import React, {useEffect} from 'react';
import {DisenrollmentTableRow} from "./DisenrollmentTableRow";


export function DisenrollmentTableBody(props) {
    const message = "Please fill out the required field.";

    useEffect(() => {
        if (props.required === 'required') {
            const checkboxes = document.querySelectorAll('input[type="checkbox"]');
            const checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
            if (!checkedOne) {
                Array.prototype.slice.call(checkboxes)[0] && Array.prototype.slice.call(checkboxes)[0].setCustomValidity(message);
            }
        }
    }, [props.required])

    function validate(event) {
        if (props.required === 'required') {
            const checkboxes = document.querySelectorAll('input[type="checkbox"]');
            const checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
            (checkedOne)
                ? Array.prototype.slice.call(checkboxes)[0].setCustomValidity('')    // reset to clear error
                : Array.prototype.slice.call(checkboxes)[0].setCustomValidity(message);     // set to enable required
        }
        props.onChange(event);
    }

    function content() {
        try {
            return <tbody id="name">{
                props.currentBenefits
                    .map((value, index) => <DisenrollmentTableRow description={value.description}
                                                                  id={value.id}
                                                                  name={props.name}
                                                                  key={index}
                                                                  selectedBenefits={props.selectedBenefits}
                                                                  checkbox={props.checkbox}
                                                                  onInput={validate}
                                                                  required={props.required}
                    />)
            }</tbody>
        } catch (e) {
            return <tbody>
            <tr>
                <td>&nbsp;</td>
            </tr>
            </tbody>;
        }
    }

    return (content());
}    
