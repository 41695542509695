import React, {useEffect, useState} from 'react';
import {MemberIdentification} from "./MemberIdentification";
import {CurrentBenefits} from "./CurrentBenefits";

export default function Disenrollment(props) {
    const [hdnMemberNumber, setHdnMemberNumber] = useState('');
    
    useEffect(() => {
        setHdnMemberNumber(props.member.memberNumber);
    },[props.member.memberNumber])
    
    return (
        <div className={props.activeTab === 0 ? "print" : "no-print"} id="DisenrollmentApplication">
            <form method="post" id="formDisenrollment" name="formDisenrollment" onSubmit={props.onDisenrollment}>
                <input type={"hidden"} 
                       id={"hdnMemberNumber"} 
                       name={"hdnMemberNumber"} 
                       value={hdnMemberNumber || ''}
                />
                <div className="row tab-contents">
                    <div className="col-md-9 pr-4">
                        <MemberIdentification {...props}
                            facilities={props.facilities}
                            member={props.member}
                            onMemberChange={props.onMemberChange}
                            disenrollmentReasons={props.disenrollmentReasons}
                            onMemberSearch={props.onMemberSearch}
                            memberNotFound={props.memberNotFound}/>
                    </div>
                    <div className={"col-md-3 background-light"}>
                        <CurrentBenefits
                            memberBenefits={props.memberBenefits}
                            currentMonthlyPremium={props.currentMonthlyPremium}
                            onBenefitsChange={props.onDisenrollmentBenefitChange}
                            selectedBenefits={props.selectedBenefits}/>
                    </div>
                </div>
                <div className="form-row" style={{backgroundColor:"#dcdcdc30", marginLeft: "-15px", marginRight: "-15px"}}>
                    <div className="form-group col text-center mt-4">
                        <button id={"disenrollSubmit"}
                                type={"submit"}
                                className={"btn btn-danger no-print"}
                                disabled={props.selectedBenefits.length === 0}> 
                            Disenroll
                        </button>
                    </div>
                </div>
            </form>
        </div>

    )
}