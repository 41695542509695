import React, {useEffect, useState} from 'react';
import {PopoverBody, UncontrolledPopover} from "reactstrap";
import '../custom.css';
import {SpinnerBasic} from "../components/Spinner-basic"
import Header from "./components/Header";
import Disenrollment from "./Disenrollment";
import TransferApplication from "../transfer/TransferApplication";
import Confirmation from "./Confirmation";
import MemberNumber from "./components/MemberNumber";
import PrintDisenrollmentPDF from "../utilities/printDisenrollmentPDF";
import moment from "moment/moment";
import {MemberIdentification} from "./MemberIdentification";
import {TextInput} from "../components/TextInput";

function Index(props) {
    const [pdfAttachment, setPdfAttachment] = useState(null);
    let _formModel;

    useEffect(() => {
        if (props.transferApp === true) {
            document.getElementById("nav-transfer-tab").tab('show');
        }
    }, [props.transferApp])

    function handleSubmit(event) {
        event.preventDefault();
    }

    function getBenefitList(formName) {
        let values = [];
        const checkboxes = document.getElementById(formName).querySelectorAll('input[type=checkbox]:checked');
        checkboxes.forEach(value => values.push(value.value));
        return values;
    }
    
    function disenrollmentDate(effectiveDate) {
        return moment(effectiveDate, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
    }

    function mapDisenrollment(form) {
        const formData = new FormData(form);
        const formName = "formDisenrollment";
        return {
            MemberNumber: formData.get("hdnMemberNumber"),
            DisenrollmentDate: disenrollmentDate(formData.get("effectiveDate")),
            SelectedBenefitIds: getBenefitList(formName),
            DisenrollmentReasonId: formData.get("disenrollmentReason"),
            AttachmentData: pdfAttachment,
            ConfirmationNumber: props.confirmationNumber,
        };
    }

    function mapTransfer(form) {
        const formData = new FormData(form);
        return {
            MemberNumber: formData.get("hdnMemberNumber"),
            NewFacilityId: formData.get("newFacilityId"),
            ConfirmationNumber: props.confirmationNumber,
            OldFacilityId: formData.get("hdnFacilityId"),
            Signature: formData.get("signature").trim(),
            EffectiveDate: formData.get("effectiveDate"),
            AttachmentData: pdfAttachment,
        };
    }

    function handlePrintPDF(pdf, urlString) {
        
        _formModel.AttachmentData = pdf;
        props.activeTab === 0
            ? props.onDisenrollment(_formModel)
            : props.onTransfer(_formModel);
    }

    function onDisenrollment(e) {
        props.onLoading(true);
        _formModel = mapDisenrollment(e.target);
        PrintDisenrollmentPDF(handlePrintPDF).then(x => console.log('onDisenrollment then'));
        e.preventDefault();
        e.stopPropagation();
    }

    function onTransfer(e) {
        props.onLoading(true);
        _formModel = mapTransfer(e.target);
        PrintDisenrollmentPDF(handlePrintPDF)
            .then(x => console.log('onTransfer then'));
        e.preventDefault();
        e.stopPropagation();
    }

    const styleObj = {backgroundColor: 'white'};

    const renderBody = () => {
        return (<>
                <div id='main-body' style={{backgroundColor: '#dcdcdc30'}}>
                    <Header step={props.step} style={styleObj}/>
                    <div className="content container" id={"content"} style={{marginTop: '42px'}}>
                        <div className={props.showConfirmation ? ' hidden ' : ''}>
                            <div className="form-row">
                                <div className="form-group col-sm-12">
                                    <MemberNumber {...props} />
                                </div>
                                <div className={"form-group"} style={{alignSelf: "center"}}>
                                    <button type={"button"} id={"medicareTooltip"}
                                            className={"tooltip-button no-print"} data-html2canvas-ignore="true">
                                        <span className={"icon-tooltip"}> </span>
                                        Support Information
                                    </button>
                                </div>
                                <div className="form-group col-sm-4" style={{alignSelf: 'end'}}>
                                    {props.memberNotFound && <span className={"text-danger"}>Member not found.</span>}
                                    {props.postError &&
                                      <span className={"text-danger"}>We apologize. There was an error posting your information.</span>}
                                </div>
                                <UncontrolledPopover placement={"right"} target={"medicareTooltip"} trigger={"hover"}>
                                    <PopoverBody>
                                        <p className={"card-text"}>
                                            If you have questions about how to obtain the Member Number, call <b><a
                                          href="tel:866-423-0415">866-423-0415</a></b>.
                                        </p>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                            <fieldset disabled={!props.enableForm}>
                                <div className={"form-row"}>
                                    <div className={"col-sm-12 mt-3"}>
                                        <nav>
                                            <div className={"nav nav-tabs"} id={"nav-tab"} role={"tablist"}>
                                                <button
                                                  className={`nav-link ${props.activeTab === 0 ? 'active' : ''} no-print `}
                                                  id={"nav-disenroll-tab"}
                                                  data-toggle={"tab"}
                                                  data-target={"#nav-disenroll"}
                                                  type={"button"}
                                                  role={"tab"}
                                                  aria-controls={"nav-disenroll"}
                                                  aria-selected={props.activeTab === 0}
                                                  onClick={props.handleNavSelect}>Disenrollment
                                                </button>
                                                <button
                                                  className={`nav-link ${props.activeTab === 1 ? 'active' : ''} no-print`}
                                                  id={"nav-transfer-tab"}
                                                data-toggle={"tab"}
                                                data-target="#nav-transfer"
                                                type={"button"}
                                                role={"tab"}
                                                aria-controls={"nav-transfer"}
                                                aria-selected={props.activeTab === 1}
                                                onClick={props.handleNavSelect}>Transfer
                                            </button>

                                        </div>
                                    </nav>
                                    <div className={"tab-content container"} id={"nav-tabContent"}
                                         style={{backgroundColor: 'white'}}>
                                        <div className={`tab-pane fade ${props.activeTab === 0 ? 'show active' : ''}`}
                                             id={"nav-disenroll"} role={"tabpanel"}>
                                            <Disenrollment {...props}
                                                           lastStep={props.lastStep}
                                                           facilities={props.facilities}
                                                           onSubmit={handleSubmit}
                                                           onMemberChange={props.onMemberChange}
                                                           disenrollmentReasons={props.disenrollmentReasons}
                                                           onMemberSearch={props.onMemberSearch}
                                                           onDisenrollment={onDisenrollment}/>
                                        </div>
                                        <div className={`tab-pane fade ${props.activeTab === 1 ? 'show active' : ''}`}
                                             id={"nav-transfer"} role={"tabpanel"}>
                                            <TransferApplication facilities={props.facilities}
                                                                 member={props.member} {...props}
                                                                 onTransfer={onTransfer}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </fieldset>
                        </div>
                        <div className={`form-row ${!props.showConfirmation && 'hidden'}`}>
                            <div className={"col-sm-12 mt-2"}>
                                <Confirmation {...props}/>
                            </div>
                        </div>
                    </div>
                    <div className={"form-row"}>
                        <div className={"col-sm-12 pl-5"}>
                            <span className={"text-muted"}><small>TC-DISENROLL-2023</small></span>
                        </div>
                    </div>
                    <footer className="no-print footer" data-html2canvas-ignore="true">
                        <div id="pe-footer" className="text-center">
                            Complete Personal and Direct Care
                            PO Box 3630
                            Little Rock, AR 72202
                            Toll-free: 1-866-423-0415 (TTY 711)
                        </div>
                    </footer>
                    <footer className="print-footer text-center">Complete Personal and Direct Care PO Box 3630 Little Rock, AR
                        72202 Toll-free: 1-866-423-0415 (TTY 711)
                    </footer>
                </div>
            </>
        );
    }

    return (
        <div className="main-page print">
            {props.loading === true
                ? <SpinnerBasic/>
                : renderBody()}
        </div>
    );
}

export default Index;