import React, {useState} from "react";

// Validate
// Mask number on blur

export function SsnInput(props) {
    const [valueDisplayed, setValueDisplayed] = useState('');
    const [valueAsEntered, setValueAsEntered] = useState('');

    function isValidSsn(num) {
        if (num.length === 9 && /\d/.test(num)){
            return true;
        }
        return num.length === 11 && /[\d\-]+/.test(num);
    }

    function maskedSsnInput() {
        return "###-##-####";
    }

    function cleanInput(entered) {
        return entered.replace(/\D/gi, '');
    }

    function onBlur(event) {
        const target = event.target;
        let value = target.value;
        if (isValidSsn(value)) {
            setValueDisplayed(maskedSsnInput());   // Replace the value in the input
            target.setCustomValidity('');
        } else {
            target.setCustomValidity('Please enter a valid SSN.');
        }
        target.reportValidity();
    }
    
    function onFocus(event) {
        const target = event.target;
        target.setCustomValidity('');
        setValueDisplayed(valueAsEntered);
    }
    
    function onChange(e) {
        const value = e.target.value;
        setValueDisplayed(value);
        setValueAsEntered(value)
        props.onMemberChange(e);
    }

    return <>
        <input required="required"
               id="memberSsn"
               name="ssn"
               onBlur={onBlur} 
               onFocus={onFocus}
               className="form-control"
               placeholder="555-55-5555"
               maxLength="11"
               pattern="\d{3}-?\d{2}-?\d{4}|#{3}-?#{2}-?#{4}" 
               value={valueDisplayed} 
               onChange={onChange}/>
        <input type="hidden" 
               id={props.id}
               name={props.name}
               value={cleanInput(props.value)}/>
    </>;

}
