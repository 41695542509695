import React from "react";
import {SsnInput} from "./SsnInput";
import {TextInput} from "./TextInput";

export function MemberIdentification(props) {
    
    return (
        <>    
            <div className="form-row form-row-spacer">
                <div className="form-group col-sm-4">
                    <TextInput label="First Name" 
                               name="firstName" 
                               id="firstName" 
                               type="text" 
                               required="required" 
                               value={props.member.firstName || ''} 
                               onChange={props.onMemberChange}/>
                </div>
                <div className="form-group col-sm-3">
                    <TextInput label="MI (optional)" 
                               name="middleInitial" 
                               id="middleInitial" 
                               type="text" 
                               value={props.member.middleInitial || ''}
                               onChange={props.onMemberChange} 
                               />
                </div>
                <div className="form-group col-sm-5">
                    <TextInput label="Last Name" 
                               name="lastName" 
                               id="lastName" 
                               type="text" 
                               required="required" 
                               value={props.member.lastName || ''}
                               onChange={props.onMemberChange}/>
                </div>
            </div>
            <div className="form-row form-row-spacer">
                <div className="form-group col-sm-12 col-md-4">
                    <TextInput label="Date of Birth" 
                               name="dateOfBirth" 
                               id="dateOfBirth" 
                               value={props.member.dateOfBirth || ''}
                               onChange={props.onMemberChange}
                               type="date" 
                               required="required"/>
                </div>
                <div className="form-group col-sm-12 col-md-4">
                    <label htmlFor="memberSsn"><span className="required no-print">*</span>Social Security Number</label>
                    <SsnInput id="ssn" name="ssn" value={props.member.ssn || ''} onMemberChange={props.onMemberChange} className="form-control"/>
                </div>
                <div className="form-group col">
                    <label htmlFor="gender"><span className="required no-print">*</span>Gender</label>
                    <select id="gender" name="gender" value={props.member.gender} onChange={props.onMemberChange} className="form-control" required="required" aria-describedby="Gender">
                        <option value="">&nbsp;</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </div>
            <div className="form-row form-row-spacer">
                <div className="form-group col-sm-12 col-md-8">
                    <label htmlFor="facilityId">
                        <span className="required no-print">*</span>
                        Name of Nursing/Residential Care/Assisted Living
                    </label>
                    <select id="facilityId" 
                            name="facilityId" 
                            className="form-control" 
                            aria-label="Facility name" 
                            value={props.member.facilityId} 
                            onChange={props.onMemberChange}
                            required="required">
                        <option key="0" value="">&nbsp;</option>
                        {
                            props.facilities.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                        }
                    </select>
                </div>
                <div className="form-group col-sm-12 col-md-4">
                    <label htmlFor="agent">
                        Agent (optional)
                    </label>
                    <select id="agent"
                            name="agent"
                            className="form-control"
                            aria-label="Facility name"
                            value={props.selectedAgent}
                            onChange={props.handleSelectedAgent}>
                        <option key="0" value="">&nbsp;</option>
                        {
                            props.agents.map(x => <option key={x.agentId} value={x.agentId}>{x.agentName}</option>)
                        }
                    </select>
                </div>
            </div>
        </>
    );
}