import React, {useEffect, useState} from "react";
import reCaptcha from "../assets/1200px-RecaptchaLogo.png";
import {TextInput} from "./TextInput";
import {NavFooter} from "./NavFooter";
import {Collapse} from "reactstrap";

export function Signature(props) {
    const display = props.step === 3 ? '' : 'hidden';
    const [signatureDisplay, setSignatureDisplay] = useState('');

    useEffect(() => {
        // Sets the signature display name
        if (props.authorizedRepresentative.relationshipToMember.toLowerCase() === 'member') {
            setSignatureDisplay(props.member.firstName + ' ' + props.member.lastName);
        } else {
            setSignatureDisplay(props.authorizedRepresentative.firstName + ' ' + props.authorizedRepresentative.lastName);
        }
    }, [props.authorizedRepresentative.relationshipToMember,
        props.authorizedRepresentative.firstName,
        props.authorizedRepresentative.lastName,
        props.member.firstName,
        props.member.lastName])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        props.resetSignature()
    }, [props.authorizedRepresentative.relationshipToMember
        , props.authorizedRepresentative.firstName
        , props.authorizedRepresentative.lastName
        , props.member.firstName
        , props.member.lastName])

    // If user is the member, digital Signature must match member first/last name
    // If user is not the member, digital Signature must match Authorized Representative first/last name
    const handleSignatureValidation = () => {
        const signature1 = document.getElementById("signature1");
        const signature2 = document.getElementById("signature2");
        const memberName = props.member.firstName.trim() + ' ' + props.member.lastName.trim();
        const authRepName = props.authorizedRepresentative.firstName.trim() + ' ' + props.authorizedRepresentative.lastName.trim();
        const relationship = props.authorizedRepresentative.relationshipToMember;
        const relationToMember = props.authorizedRepresentative.relationshipToMember === "REP" ? "Authorized Representative" : "Power of Attorney";

        // Do the two signature fields match each other?
        if (signature1.value !== signature2.value) {
            signature1.setCustomValidity('Signatures must match.');
            return; // fails, no need to do more
        } else {
            signature1.setCustomValidity('');
        }
        
        switch (relationship.toLowerCase()) {
            case "member":
                if (signature1.value !== memberName) {
                    signature1.setCustomValidity('Signature must match the Member name.');
                } else {
                    signature1.setCustomValidity('');
                }
                if (signature2.value !== memberName) {
                    signature2.setCustomValidity('Signature must match the Member name.');
                } else {
                    signature2.setCustomValidity('');
                }
                break;
            case "rep":
            case "poa":
                if (signature1.value !== authRepName) {
                    signature1.setCustomValidity('Signature must match the ' + relationToMember + '.');
                } else {
                    signature1.setCustomValidity('');
                }
                if (signature2.value !== authRepName) {
                    signature2.setCustomValidity('Signature must match the ' + relationToMember + '.');
                } else {
                    signature2.setCustomValidity('');
                }
                break;
        }
    }

    function handleChange(event) {
        props.handleSignatureDateChange(event);
    }

    return (
        <div className={`container ${display} print break-before`} id="signature">
            <form method="post" id="formSignature" name="formSignature" onSubmit={props.onSubmit}>
                <div className="row mt-5">
                    <div className="col">
                        <h2 className="brand-color-lightgray">Signature</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row mt-3">
                            <div className="col">
                                <h5 className="brand-color-red mb-3">Section 1 | Member Signature</h5>
                                <hr/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3"><label htmlFor="applicantName">Applicant's Name</label></div>
                            <div className="col-sm-7 text-center">
                                <input id="applicantName" readOnly="readonly" className="form-control"
                                       value={props.member.firstName + ' ' + props.member.lastName}/>
                            </div>
                            <div className="col-sm-2">&nbsp;</div>
                        </div>
                        <div className="row mt-4 mt-sm-2">
                            <div className="col-sm-12">
                                <b>I am the</b>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12">
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="Member">
                                        <input type="radio" className="form-check-input"
                                               id="Member"
                                               name="relationshipToMember"
                                               checked={props.authorizedRepresentative.relationshipToMember === "MEMBER"}
                                               value="MEMBER"
                                               onChange={props.handleAuthorizedRepChange}
                                               required={true}/>
                                        Member
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="authorizedRepresentative">
                                        <input type="radio" className="form-check-input"
                                               id="authorizedRepresentative"
                                               name="relationshipToMember"
                                               checked={props.authorizedRepresentative.relationshipToMember === "REP"}
                                               value="REP"
                                               onChange={props.handleAuthorizedRepChange}/>
                                        Authorized Representative
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="PowerOfAttorney">
                                        <input type="radio" className="form-check-input"
                                               id="PowerOfAttorney"
                                               name="relationshipToMember"
                                               value="POA"
                                               checked={props.authorizedRepresentative.relationshipToMember === "POA"}
                                               onChange={props.handleAuthorizedRepChange}/>
                                        Power of Attorney
                                    </label>
                                </div>
                            </div>
                        </div>

                        <Collapse isOpen={props.showAuthRep}>
                            <div className="col-sm-12" id="relationshipAddress">
                                <fieldset id="authRepAddress">
                                    <div className="form-row">
                                        <div className="col-sm-5">
                                            <label htmlFor="firstName">First Name</label>
                                            <input type="text" id="firstName"
                                                   value={props.authorizedRepresentative.firstName || ''}
                                                   onChange={props.handleAuthorizedRepChange} name="firstName"
                                                   className="form-control"/>
                                        </div>
                                        <div className="col-sm-2">
                                            <label htmlFor="middleInitial">MI</label>
                                            <input type="text" id="middleInitial" name="middleInitial"
                                                   value={props.authorizedRepresentative.middleInitial || ''}
                                                   onChange={props.handleAuthorizedRepChange} className="form-control"/>
                                        </div>
                                        <div className="col-sm-5">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input type="text" id="lastName"
                                                   value={props.authorizedRepresentative.lastName || ''}
                                                   onChange={props.handleAuthorizedRepChange} name="lastName"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col">
                                            <label htmlFor="address1">Address</label>
                                            <input type="text" id="address1"
                                                   value={props.authorizedRepresentative.address1 || ''}
                                                   onChange={props.handleAuthorizedRepChange} name="address1"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-sm-7">
                                            <label htmlFor="city">City</label>
                                            <input type="text" id="city"
                                                   value={props.authorizedRepresentative.city || ''}
                                                   onChange={props.handleAuthorizedRepChange} name="city"
                                                   className="form-control"/>
                                        </div>
                                        <div className="col-sm-2">
                                            <label htmlFor="stateOrProvince">State</label>
                                            <input type="text" id="stateOrProvince"
                                                   value={props.authorizedRepresentative.stateOrProvince || ''}
                                                   onChange={props.handleAuthorizedRepChange} name="stateOrProvince"
                                                   className="form-control"/>
                                        </div>
                                        <div className="col-sm-3">
                                            <label htmlFor="zipPostalCode">Zip Code</label>
                                            <input type="text" id="zipPostalCode"
                                                   value={props.authorizedRepresentative.zipPostalCode || ''}
                                                   name="zipPostalCode"
                                                   onChange={props.handleAuthorizedRepChange} className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-sm-6">
                                            <label htmlFor="phoneNumber">Phone</label>
                                            <input type="text" id="phoneNumber"
                                                   value={props.authorizedRepresentative.phoneNumber || ''}
                                                   onChange={props.handleAuthorizedRepChange} name="phoneNumber"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </Collapse>

                        <div className="row mb-2 mt-4 no-print">
                            <div className="col-sm-6">
                                <div className="card">
                                    <div className="card-body" style={{'padding': '0.25rem', 'paddingLeft': '1.25rem'}}>
                                        <label htmlFor="notRobot">
                                            <input type="checkbox"
                                                   id="notRobot"
                                                   name="notRobot"
                                                   required="required"
                                                   checked={props.recaptchaValue}
                                                   value={props.recaptchaValue.toString()}
                                                   onChange={props.handleRecaptchaChange}/>&nbsp; I am not a
                                            robot</label>
                                        <img src={reCaptcha} height="70px" className="ml-5 pl-4" alt="reCaptcha"
                                             data-html2canvas-ignore="true"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-sm-12">
                                <label htmlFor="signatureConfirmation">
                                    <input type="checkbox"
                                           id="signatureConfirmation"
                                           checked={props.signatureConfirmationCheck}
                                           onChange={props.handleSignatureConfirmationCheckChange}
                                           name="signatureConfirmation"
                                           required="required"/>&nbsp; By entering my name below I am indicating my
                                    intent to electronically sign this application
                                    and warrant that all the information I have provided is true, complete, and
                                    accurate.
                                </label>
                            </div>
                        </div>
                        <div className="row mb-2 mt-4">
                            <div className="col-sm-12 col-lg-6">
                                <TextInput label="Digital Signature" name="signature1" id="signature1" type="text"
                                           value={props.digitalSignature}
                                           onChange={props.handleDigitalSignatureChange}
                                           onBlur={handleSignatureValidation}
                                           required="required"/>
                                <small className={"text-muted"}>{signatureDisplay}</small>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <TextInput label="Confirm Digital Signature" name="signature2" id="signature2"
                                           type="text" 
                                           value={props.digitalSignatureConfirmation}
                                           onChange={props.handleDigitalSignatureConfirmationChange} 
                                           onBlur={handleSignatureValidation} 
                                           required="required"/>
                                <small className={"text-muted"}>{signatureDisplay}</small>
                            </div>
                        </div>
                        <div className="row mb-2 mt-2">
                            <div className="col-sm-6">
                                <TextInput label="Date" name="signatureDate" id="signatureDate" type="date"
                                           required="required" value={props.signatureDate} onChange={handleChange}/>
                            </div>
                        </div>
                    </div>

                </div>
                <NavFooter step={props.step}
                           lastStep={props.lastStep}
                           display={props.step === 3}
                           onChange={props.onChange}/>
            </form>
        </div>
    )
}