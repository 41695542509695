import React from "react";
import {TextInput} from "../../components/TextInput";

function MemberNumber(props) {

    function memberValidate(memberNumber) {
        const valid = /amc\b|AMC\b|amc[0-9]{6}|AMC[0-9]{6}/.test(memberNumber.value);
        if (valid === true ) {
            memberNumber.setCustomValidity('');
        } else {
            memberNumber.setCustomValidity('Member Number must start with AMC followed by 6 digits.');
        }
    }

    function memberSearch(event) {
        const memberNumber = document.getElementById("memberNumber");
        memberValidate(memberNumber);
        if (memberNumber.reportValidity()){
            props.onMemberSearch(event);
        }
        event.stopPropagation();
        return false;
    }
    
    return (
      <form id={"formMemberNumber"} name={"formMemberNumber"} className={"row"}>
          <div className={"form-group col-sm-2"}>
              <label htmlFor="memberNumber">Member Number</label>
              <div className={"input-group"}>
                  <input type="text"
                         className="form-control"
                         id="memberNumber"
                         name="memberNumber"
                         required={true}
                         value={props.member.memberNumber || ''}
                         onChange={props.onMemberChange}
                         maxLength="9"
                         aria-describedby="Member Number"
                  />
              </div>
          </div>
          <div className="form-group col-sm-2">
              <TextInput label="First Name"
                         name="firstName"
                         id="firstName"
                         type="text"
                         value={props.member.firstName || ''}
                         onChange={props.onMemberChange}/>
          </div>
          <div className="form-group col-sm-2">
              <TextInput label="Last Name"
                         name="lastName"
                         id="lastName"
                         type="text"
                         value={props.member.lastName || ''}
                         onChange={props.onMemberChange}/>
          </div>
          <div className="form-group col-sm-2">
              <TextInput label="Date of Birth"
                         name="dateOfBirth"
                         id="dateOfBirth"
                         type={"date"}
                         value={props.member.dateOfBirth || ''}
                         onChange={props.onMemberChange}/>
          </div>
          <div className={"form-group col-sm-2"}>
              <button className="btn btn-success no-print" type="button" style={{marginTop : 33 + "px"}}
                      onClick={memberSearch}>Search
              </button>
          </div>
      </form>);
}

export default MemberNumber;