import {jsPDF} from "jspdf";

/* Add this attribute to hide items from printing
* data-html2canvas-ignore="true"
* */

const printDisenrollmentPDF = async function (callback) {
    const doc = new jsPDF({
        orientation: "p",
        format: "legal",
        unit: "px",
        hotfixes: ["px_scaling"]
    });

    const elementHandler = {
        '#ignorePDF': function (element) {
            return false;
        }
    }

    const printArea = document.getElementById('PrintApplication');
    if (printArea.classList.contains('hidden')) {
        printArea.classList.remove('hidden');
    }

    const blobToBase64 = async blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            }
        });
    }
    
    const body = document.getElementById("PrintApplication");

    let width = 776;
    doc.html(body, {
        autoPaging: 'slice',
        width: width,
        windowWidth: width,
        margin:0,
        image: 'png',
        filename: 'Complete Disenrollment/Transfer Form.pdf',
        html2canvas: {windowWidth: width},
        callback: async function (doc) {
            // Create the file
            let pdf = doc.output('blob');
            let urlString = doc.output('datauristring');
            printArea.classList.add('hidden');
            const base64String = await blobToBase64(pdf);
            callback(base64String, urlString);
        },
        elementHandler: elementHandler
    });
}

export default printDisenrollmentPDF;