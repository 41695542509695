import React, {useEffect, useState} from "react";
import {NavFooter} from "./NavFooter";

export function Confirmation(props) {
    const display = props.step === 4 ? '' : 'hidden';
    const [formattedSsn, setFormattedSsn] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [formattedPhone, setFormattedPhone] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    
    useEffect(() => {
        setFormattedSsn(formatSSN(props.member.ssn))
    },[props.member.ssn])

    useEffect(() => {
        setFormattedDate(formatDate(props.member.dateOfBirth))
    }, [props.member.dateOfBirth])

    useEffect(() => {
        setFormattedPhone(formatPhoneNumber(props.authorizedRepresentative.phoneNumber))
    }, [props.authorizedRepresentative.phoneNumber])
    
    // Reset the form acceptance checkbox
    useEffect(() => {
        props.handleResetFormAccept();
    }, [props.step]);

    function formatSSN(value) {
        return value === undefined ? '' : value.substring(0, 3) + '-' + value.substring(3, 5) + '-' + value.substring(5);
    }

    function formatDate(value) {
        if (value === undefined) return '';
        const d = value.split('-');
        return d[1] + '/' + d[2] + '/' + d[0];
    }

    function formatPhoneNumber(value) {
        if (value === undefined || value === '') return '';
        const digits = value.replace(/\D/gi, '');
        return '(' + digits.substring(0, 3) + ') ' + digits.substring(3, 6) + '-' + digits.substring(6);
    }
    
    function handleAccept(event){
        (event.currentTarget.checked) ? setIsDisabled(false) : setIsDisabled(true);
        props.handleFormAcceptCheckChange(event);
    }
    
    function relationshipToMember(){
        const relation = props.authorizedRepresentative.relationshipToMember.toLowerCase();
        switch (relation) {
            case "member":
                return "Member";
            case "rep":
                return "Authorized Representative";
            case "poa": 
                return "Power of Attorney";
            default:
                return "";
        }
    }

    return (
        <div className={`container ${display} print break-before`} id="confirmation">
            <form method="post" id="formConfirmation" name="formConfirmation" onSubmit={props.onSubmit}>
                <div className="row mt-5">
                    <div className="col">
                        <h2 className="brand-color-lightgray">Confirmation</h2>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <h5 className="brand-color-red mb-3">Section 1 | Review</h5>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-x-sm-12 col-md-6 col-lg-3">
                        <br className="mobile-break" />
                        <label className="semi-bold brand-color-lightgray mb-0">Enrollee Information</label>
                        <hr className="mt-1"/>
                        <section>
                            <table>
                                <tbody>
                                <tr>
                                    <td>{props.member.firstName + ' ' + props.member.middleInitial + ' ' + props.member.lastName}</td>
                                </tr>
                                <tr>
                                    <td>{formattedSsn}</td>
                                </tr>
                                <tr>
                                    <td>{formattedDate}</td>
                                </tr>
                                <tr>
                                    <td>{props.selectedFacility.name}</td>
                                </tr>
                                <tr>
                                    <td>{props.selectedFacility.location.address1}</td>
                                </tr>
                                <tr>
                                    <td>{props.selectedFacility.location.city + ', ' + props.selectedFacility.location.stateOrProvince + ' ' + props.selectedFacility.location.zipPostalCode}</td>
                                </tr>
                                <tr>
                                    <td>{props.selectedFacility.phone}</td>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>
                    {props.authorizedRepresentative.relationshipToMember.toLowerCase() !== 'member' &&
                        <div
                            className={`col-x-sm-12 col-md-6 col-lg-3 ${props.authorizedRepresentative.lastName === undefined ? 'hidden' : ''}`}>
                            <br className="mobile-break"/>
                            <label className="semi-bold brand-color-lightgray mb-0">{relationshipToMember()}</label>
                            <hr className="mt-1"/>
                            <section>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>{props.authorizedRepresentative.firstName + ' ' + props.authorizedRepresentative.middleInitial + ' ' + props.authorizedRepresentative.lastName}</td>
                                    </tr>
                                    <tr>
                                        <td>{props.authorizedRepresentative.relationshipToMember}</td>
                                    </tr>
                                    <tr>
                                        <td>{props.authorizedRepresentative.address1}</td>
                                    </tr>
                                    <tr>
                                        <td>{props.authorizedRepresentative.city
                                            + ', '
                                            + props.authorizedRepresentative.stateOrProvince
                                            + ' '
                                            + props.authorizedRepresentative.zipPostalCode}</td>
                                    </tr>
                                    <tr>
                                        <td>{formattedPhone}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </section>
                        </div>
                    }
                    <div className="col-x-sm-12 col-md-6 col-lg-2">
                        <br className="mobile-break" />
                        {props.isMember ? <label className="semi-bold brand-color-lightgray mb-0">Added Coverage</label>
                            : <label className="semi-bold brand-color-lightgray mb-0">Coverage</label>}
                        <hr className="mt-1"/>
                        <section>
                            <table style={{width: '100%'}}>
                                <tbody>
                                {props.selectedBenefits.map((description, index) => {
                                    const bene = props.planBenefits.find(pb => pb.description === description)
                                    return <tr key={index}>
                                        <td>{description}</td>
                                        <td className={"text-right"}>(${bene.premium}/month)</td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </section>
                    </div>
                    {props.isMember && 
                    <div className="col-x-sm-12 col-md-6 col-lg-2">
                        <br className="mobile-break" />
                        <label className="semi-bold brand-color-lightgray mb-0">Existing Coverage</label>
                        <hr className="mt-1"/>
                        <section>
                            <table style={{width: '100%'}}>
                                <tbody>
                                {props.memberBenefits.map((bene) => {
                                    return <tr key={bene.id}>
                                        <td>{bene.description}</td>
                                        <td className={"text-right"}>(${bene.premium}/month)</td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </section>
                    </div>
                    }
                    <div className="col-x-sm-12 col mt-sm-3 mt-md-0">
                        <br className="mobile-break" />
                        {props.isMember ?
                            <label className="semi-bold brand-color-lightgray mb-0">Monthly Premium</label>
                            : <label className="semi-bold brand-color-lightgray mb-0">Monthly Premium</label>}
                        <hr className="mt-1"/>
                        <section>
                            <table style={{width: '100%'}}>
                                <tbody>
                                <tr>
                                    <td>${props.monthlyPremium + props.currentMonthlyPremium}</td>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col">
                        <h5 className="brand-color-red mb-3">Section 2 | Acknowledgment</h5>
                    </div>
                </div>
                <div className="row mr-3">
                    <div className="col pt-2 acknowledge">
                        <p>
                            In submitting this application to ASSI for coverage, I understand that if my application is
                            accepted, my contract will consist of the Policy issued to me, along with a Policy Schedule
                            containing information about my coverage, such as my premium and effective date.
                            I understand that I am required to pay the monthly premium for the duration of the contract.
                            I
                            further agree that the coverage requested is subject to the approval of ASSI, that no
                            coverage
                            shall become effective until ASSI receives my initial premium, and that no representative
                            has
                            authority to make changes or modify this application.
                            <br/><br/>
                            I certify that all of the information contained in this application is true and correct to
                            the
                            best of my knowledge. I further understand that misrepresentation of submitted data may
                            cause
                            this application and subsequent Policy to be null and void. I understand that the Policy
                            will
                            become effective on the first day of the month following approval of this application and
                            ASSI’s
                            receipt of the initial premium.
                            <br/><br/>
                            By my submission of this application, I attest that I am a resident of a nursing,
                            residential
                            care, or assisted living facility in Arkansas.
                            <br/><br/>
                            Any person who knowingly presents a false or fraudulent claim for payment of a loss or
                            benefit
                            to an insurer or knowingly presents false information in an application for insurance is
                            guilty
                            of a crime and may be subject to fines and confinement in prison.
                        </p>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col form-check">
                        <sup className="required">*</sup>
                        <label htmlFor="agreement" className="confirmAgree">
                            <input type="checkbox" id="agreement" name="agreement" checked={props.formAcceptCheck} required="required" onChange={handleAccept}/> I understand
                            that
                            by applying for
                            coverage, I agree to the Acknowledgment above.</label>
                    </div>
                </div>

                <NavFooter step={props.step}
                           lastStep={props.lastStep}
                           display={props.step === 4}
                           onChange={props.onChange} 
                           disabled={isDisabled}/>
            </form>
        </div>
    );
}