import React from "react";
import {DisenrollmentTableBody} from "./components/DisenrollmentTableBody";

export function CurrentBenefits(props) {
    return (
        <section className={"print "} id="currentBenefits">
            <div className="row">
                <div className="col background-light-header">
                    <h6 className="p-2 mb-0">Existing benefits</h6>
                </div>
            </div>
            <div className="row">
                <div className="col pt-2 pb-2" style={{borderBottom: 'solid 1px gainsboro'}}>
                    <small className="text-muted brand-color-red p-2">
                        Select benefits to disenroll.
                    </small>
                    
                </div>
            </div>
            <div className="row mt-2 table-tall">
                <div className="col pl-3">
                    <table className="table table-sm table-borderless">
                        <DisenrollmentTableBody
                            currentBenefits={props.memberBenefits} 
                            selectedBenefits={props.selectedBenefits} 
                            onChange={props.onBenefitsChange} 
                            checkbox="true" height={"50px"}
                            required={"required"}
                        />
                    </table>
                </div>
            </div>
        </section>);
}