import React, {useEffect, useState} from "react";
import {PrintComponent} from "./components/PrintComponent";
import Cleave from 'cleave.js/react'

export default function Confirmation(props) {
    const [confirmationNumber, setConfirmationNumber] = useState('');
    
    useEffect(() => {
        if (props.member.confirmationNumber) {
            const number = props.member.confirmationNumber.toString().padStart(10, '0');
            setConfirmationNumber(number);
        }
    }, [props.member.confirmationNumber])
    
    return (
        <div className={`container no-print break-before`} id="finished" data-html2canvas-ignore="true">
            <div className="row">
                <div className="col-sm-6">
                    <h3 className="brand-color-red">Thank you!</h3>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col-sm-6 pr-5">
                    <h5 className="brand-color-lightgray">We’ve received your completed form.</h5>
                    <ul className="list-tall">
                        <li>If a Disenrollment, a Verification/ID letter will be mailed to the address we have on file.
                        </li>
                        <li>If a Transfer, we will update the member’s address and contact the new facility to confirm
                            benefits.
                        </li>
                    </ul>
                </div>
                <div className="col-sm-6">
                    <div className="row mb-2">
                        <div className="col pl-5">
                            <h5 className="brand-color-lightgray">Completed Form</h5>
                            <p className={"mt-2"}>Select an option below to Print or Save a copy of the completed
                                form.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col pl-5">
                            <PrintComponent {...props}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"row mb-5 mt-5"}>
                <div className={"col-sm-6 offset-3"}>
                    <div className={"card"}>
                        <div className={"card-body confirmation-number"}>
                            <p className={"card-text brand-color-lightgray text-center"}>
                                <b className={"pr-3"} style={{fontSize: '20px'}}>Confirmation Number:</b>
                                <Cleave options={{
                                    delimiter: '-',
                                    blocks: [3, 4, 3]
                                }}
                                        className="form-control confirmation-disenroll-transfer"
                                        id="confirmationNumber"
                                        name="confirmationNumber"
                                        value={confirmationNumber}
                                        constructor={''}
                                        readOnly="readOnly"/>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <hr/>
            <div className="row justify-content-center mt-3">
                <div className="col-sm-4 mt-3">
                    <h5 className="brand-color-lightgray">Contact Us</h5>
                    <div className="row">
                        <div className="col-sm-3">Phone:</div>
                        <div className="col-sm-9">866-423-0415</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">Email:</div>
                        <div className="col-sm-9">TCEnrollment@CompleteBenefitPlans.com</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3">Website:</div>
                        <div className="col-sm-9 mb-3"><a
                            href="https://completebenefitplans.com/">CompleteBenefitPlans.com</a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}