import React from 'react';

export function SpinnerBasic(props) {
    const style = {position: "fixed", top: "40%", left: "50%", transform: "translate(-50%, -50%)"};

    return(
        <div style={style}>
            
            <div className="row">
                <div className="col text-center">
                    <div className="spinner-border" role="status">&nbsp;</div>
                </div>
            </div>

            <div className="row">
                <div className="col text-center mt-5">
                    Working ...
                </div>
            </div>
        </div>
    )
}

