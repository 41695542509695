import React from 'react';
import {SpinnerBasic} from "./Spinner-basic";
import {NavMenu} from "./NavMenu";
import {Application} from "./Application";
import {Coverage} from "./Coverage";
import {Signature} from "./Signature";
import {Confirmation} from "./Confirmation";
import {Complete} from "./Complete";
import '../custom.css';
import printPDF from "../utilities/printPDF";

export function Home(props) {
    let formCoverage;
    let formSignature;
    function handleSubmit(event) {
        event.preventDefault();
        switch (props.step) {
            case 1:
                const formModel = getFormModel('formMember');
                const memberModel = mapMember(formModel);
                props.onMemberSubmit(memberModel);
                break;
            case 2:
                const benefitModel = mapBenefits('formCoverage');
                props.onCoverageSubmit(benefitModel);
                break;
            case 3:
                const signature = getFormModel('formSignature');
                const signatureModel = mapAuthorizedRepresentative(signature);
                props.onSignatureSubmit(signatureModel);
                break;
            case 4:
                props.onLoading(true);
                formCoverage = getFormModel('formCoverage');
                formCoverage.memberBenefitIds = getCheckboxIdList('formCoverage');
                formSignature = getFormModel('formSignature');
                printPDF(props.isMember, handlePrintPDF);
                break;
            default:
                props.onChange(props.step + 1);  // advance step
        }

    }
    
    function handlePrintPDF(pdf){
        const model = mapPostModel(props.member, formCoverage, formSignature, pdf);
        props.onFormSubmit(model);
    }

    function getFormModel(formName) {
        const form = document.getElementById(formName);
        if (typeof form === 'undefined') return false;
        const formData = new FormData(form);
        return Object.fromEntries(formData.entries());
    }

    function mapMember(model) {
        return {
            memberNumber: model.memberNumber,
            ssn: model.ssn,
            lastName: model.lastName,
            dateOfBirth: model.dateOfBirth,
            firstName: model.firstName,
            middleInitial: model.middleInitial,
            facilityId: model.facilityId,
            gender: model.gender
        }
    }

    function mapAuthorizedRepresentative(signature) {
        return {
            lastName: signature.lastName,
            firstName: signature.firstName,
            middleInitial: signature.middleInitial,
            address1: signature.address1,
            city: signature.city,
            stateOrProvince: signature.stateOrProvince,
            zipPostalCode: signature.zipPostalCode,
            phoneNumber: signature.phoneNumber,
            relationshipToMember: signature.relationshipToMember
        }
    }

    function mapBenefits(formName) {
        return getCheckboxValueList(formName);
    }

    function getCheckboxIdList(formName) {
        let ids = [];
        const checkboxes = document.getElementById(formName).querySelectorAll('input[type=checkbox]:checked');
        checkboxes.forEach(value => ids.push(value.id));
        return ids;
    }

    function getCheckboxValueList(formName) {
        let values = [];
        const checkboxes = document
            .getElementById(formName)
            .querySelectorAll('input[type=checkbox]:checked');
        checkboxes.forEach(value => values.push(value.name));
        return values;
    }

    function mapPostModel(member, coverage, signature, attachment) {
        return {
            Id: 0,
            EffectiveDate: coverage.effectiveMonth + '-01',
            LastName: member.lastName,
            FirstName: member.firstName,
            MiddleInitial: member.middleInitial,
            DateOfBirth: member.dateOfBirth,
            Gender: member.gender,
            Ssn: member.ssn,
            Mbi: null,
            MemberBenefitIds: coverage.memberBenefitIds,
            PremiumPaymentMethodId: parseInt(coverage.paymentOptions, 10) || 0,
            PremiumPaymentMethod: coverage.mailingAddressIsFacilityOrPoa || '',
            FacilityId: parseInt(member.facilityId, 10),
            Facility: null,
            AuthorizedRepresentative: {
                LastName: signature.lastName,
                FirstName: signature.firstName,
                MiddleInitial: signature.middleInitial,
                Address: {
                    Address1: signature.address1,
                    Address2: null,
                    Address3: null,
                    City: signature.city,
                    StateOrProvince: signature.stateOrProvince,
                    ZipPostalCode: signature.zipPostalCode,
                    Country: null,
                    EmailAddress: null
                },
                PhoneNumber: signature.phoneNumber,
                RelationshipToMember: signature.relationshipToMember === 'MEMBER' 
                    ?	null 
                    :	signature.relationshipToMember
            },
            MailingAddressIsPoaOrFacility: coverage.mailingAddressIsFacilityOrPoa || null,
            AgentId: parseInt(props.selectedAgent, 10),
            SnpAgent: null,
            DesiredEffectiveMonth: coverage.effectiveMonth + '-01',
            MemberNumber: member.memberNumber,
            MaPlanId: props.maPlanId,
            MaPlan: null,
            Status: "",
            WhenStatusChanged: "",
            CreatedDate: "",
            RecordValidFrom: "",
            RecordValidTo: "",
            Errors: null,
            EffectiveDateOverrideReasonId: props.overrideReasonId === '' ? null : props.overrideReasonId,
            EffectiveDateOverrideReasonOther: props.overrideReasonOther === '' ? null : props.overrideReasonOther,
            AttachmentData: attachment
        }
    }
    
    const renderBody = () => {
        return (
            <div id='main-body'>
                <NavMenu step={props.step}/>
                <div className="content" id={"content"}>

                    <Application step={props.step} {...props}
                                 lastStep={props.lastStep}
                                 facilities={props.facilities}
                                 onSubmit={handleSubmit}/>

                    <Coverage step={props.step} {...props}
                              lastStep={props.lastStep}
                              selectedBenefits={props.selectedBenefits}
                              onChange={props.onChange}
                              onSubmit={handleSubmit}/>

                    <Signature step={props.step} {...props}
                               lastStep={props.lastStep}
                               onChange={props.onChange}
                               onSubmit={handleSubmit}
                               display={props.step === 3}/>

                    <Confirmation step={props.step} {...props}
                                  lastStep={props.lastStep}
                                  onChange={props.onChange}
                                  onSubmit={handleSubmit}
                                  display={props.step === 4}/>

                    <Complete step={props.step} {...props}
                              lastStep={props.lastStep}
                              onChange={props.onChange}
                              onSubmit={handleSubmit}
                              display={props.step === 5}/>
                </div>

                <footer className="no-print footer" data-html2canvas-ignore="true">
                        <div id="pe-footer" className="text-center">
                            Complete Personal and Direct Care
                            PO Box 3630
                            Little Rock, AR 72202
                            Toll-free: 1-866-423-0415 (TTY 711)
                        </div>
                </footer>
                <footer className="print-footer text-center print">Complete Personal and Direct Care PO Box 3630 Little Rock, AR 72202 Toll-free: 1-866-423-0415 (TTY 711)</footer>
            </div>
        );
    }

    return (
        <div className="main-page print">
            {props.loading === true
                ? <SpinnerBasic/>
                : renderBody()}
        </div>
    );
}


