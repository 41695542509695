import React from 'react';

export function TextInput({label, required, ...props}) {

    const requiredStar = required === 'required'
        ? <span className="required no-print">*</span>
        : null;

    function handleChange() {
        return false;
    }

    return (
        <>
            <label htmlFor={props.id || props.name}>{requiredStar}{label}</label>
            <input id={props.id}
                   name={props.name}
                   value={props.value}
                   type={props.type || 'text'}
                   className="form-control"
                   onChange={props.onChange || handleChange}
                   onBlur={props.onBlur || handleChange}
                   required={required}
                   aria-label={label}
                   {...props}/>
        </>
    );
}



