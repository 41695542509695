import React from "react";
import EffectiveDate from "./EffectiveDate";
import {TableBody} from "./TableBody";

export function AvailableBenefits(props) {
     return (
        <section className={"print " + ((props.display) ? '' : 'hidden')} id="availableBenefits">
            <br className="mobile-break"/>
            <div className="row">
                <div className="col mb-2">
                    <h5 className="brand-color-red mb-3">Section 2 | Available Benefits</h5>
                    <hr/>
                    {props.availableBenefits.length === 0
                        ? <h5>No additional benefits available.</h5>
                        : <p>Select the benefit coverage option(s)</p>}
                </div>
            </div>
            {props.availableBenefits.length !== 0 && <>
            <div className="row mt-3 table-tall">
                <div className="col">
                    <table className="table table-sm">
                        <thead>
                        <tr>
                            <th className="no-top-border">Benefit</th>
                            <th className="no-top-border">Premium</th>
                        </tr>
                        </thead>
                        
                        <TableBody name="availableBenefits" 
                                   value={props.availableBenefits} 
                                   selectedBenefits={props.selectedBenefits}
                                   onChange={props.handleBenefitChange} 
                                   required="required"/>
                    </table>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label className="semi-bold">New Monthly Premium </label>
                </div>
                <div className="col form-group form-inline">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                        </div>
                        <input type="text" 
                               className="form-control" 
                               id="newMonthlyPremium" 
                               name="newMonthlyPremium"
                               value={props.monthlyPremium + props.currentMonthlyPremium} 
                               readOnly/>
                    </div>
                </div>
            </div>
            <EffectiveDate
                minEffectiveMonth={props.minEffectiveMonth}
                effectiveMonth={props.effectiveMonth} 
                handleEffectiveMonthChange={props.handleEffectiveMonthChange}
            />    
            </>
            }    
        </section>);
}