import React from "react";

export function Complete(props) {
    const display = props.step === 5 ? '' : 'hidden';

    return (
        <div className={`container ${display} print break-before`} id="finished" data-html2canvas-ignore="true">
            <form method="post" id="formFinished" name="formFinished" onSubmit={props.onSubmit}>
                <div className="row mt-5">
                    <div className="col">
                        <h3 className="brand-color-red">Thank you for choosing Complete Personal and Direct Care for
                            your
                            healthcare needs!</h3>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-sm-6 pr-5">
                        <h5 className="brand-color-lightgray">What Happens Next</h5>
                        <p>Once we have processed and approved your enrollment information:</p>
                        <ol className="list-tall">
                            <li>Your account will be created or updated (for existing members) with your benefit plan
                                elections.
                            </li>
                            <li>An Enrollment Verification letter, including a Member ID Card, will be mailed to the
                                address we have
                                on file.
                            </li>
                            <li>Our participating providers work directly with your location to schedule clinic days and
                                exam visits. Please check with your facility staff to determine the schedule for the
                                benefit
                                plan(s) you have selected.
                            </li>
                            <li>Remember:
                                <ul>
                                    <li>Services such as dentures and hearing devices require a 6-month waiting
                                        period.
                                    </li>
                                    <li>Services provided by non-participating providers are subject to a deductible.
                                        The
                                        deductible varies by the benefit plan(s) in which you are enrolled.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div className="col-sm-6">
                        <div className="row mb-2">
                            <div className="col pl-5">
                                <h5 className="brand-color-lightgray">Completed Application</h5>
                                <p>You can print or save a copy of the completed application by selecting an option
                                    below.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mt-3 pl-5">
                                <button className="btn btn-danger w-100" type="button" onClick={() => window.print()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-printer" viewBox="0 0 16 16">
                                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                                        <path
                                            d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                                    </svg>
                                    &nbsp;&nbsp;Print / Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <hr/>
                <div className="row justify-content-center mt-3">
                    <div className="col-sm-4 mt-3">
                        <h5 className="brand-color-lightgray">Contact Us</h5>
                        <div className="row">
                            <div className="col-sm-3">Phone:</div>
                            <div className="col-sm-9">866-423-0415</div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3">Email:</div>
                            <div className="col-sm-9">enrollment@completebenefitplans.com</div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3">Website:</div>
                            <div className="col-sm-9 mb-3"><a
                                href="https://completebenefitplans.com/"> CompleteBenefitPlans.com</a></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}