import React from "react";
import {TextInput} from "../components/TextInput";
import moment from "moment";
import EffectiveDate from "../components/EffectiveDate.js";

export function MemberIdentification(props) {
    function effectiveDateDisplay() {
        return moment(props.effectiveMonth, "YYYY-MM").endOf("month").format("MMMM DD, YYYY");
    }
    
    return (
        <>
            <div className="form-row">
                <div className="form-group col-sm-12">
                    <label htmlFor="disenrollmentReason">Reason</label>
                    <select id="disenrollmentReason"
                            name="disenrollmentReason"
                            className="form-control"
                            required="required"
                            aria-label="Disenrollment Reason"
                            value={props.disenrollmentReason}
                            onChange={props.handleDisenrollmentReason}>
                        <option key="0" value="">&nbsp;</option>
                        {
                            props.disenrollmentReasons &&
                            props.disenrollmentReasons.map(x => <option key={x.id}
                                                                        value={x.id}>{x.description}</option>)
                        }
                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-sm-4">
                    <TextInput label={"Signature"}
                               required={"required"}
                               name={"signature"}
                               onChange={props.handleDisenrollmentSignature}
                               value={props.digitalSignature}
                    />
                </div>
                <div className="form-group col-sm-4">
                    <label htmlFor="relationshipToMember">Relationship</label>
                    <select id="relationshipToMember"
                            name="relationshipToMember"
                            className="form-control"
                            required="required"
                            aria-label="Relationship"
                            value={props.authorizedRepresentative.relationshipToMember}
                            onChange={props.handleAuthorizedRepChange}>
                        <option key="0" value="">&nbsp;</option>
                        <option key="1" value="1">Facility Representative</option>
                        <option key="2" value="2">Member</option>
                        <option key="3" value="3">Plan Representative</option>
                        <option key="4" value="4">POA</option>
                        <option key="5" value="5">Responsible Party</option>
                    </select>
                </div>
                <div className="form-group col-sm-4" style={{marginTop: "-1.5rem"}}>
                    <input id={"effectiveDate"} name={"effectiveDate"} value={props.effectiveMonth} type={"text"}
                           hidden={true} onChange={() => {
                    }}/>
                    <EffectiveDate
                        minEffectiveMonth={"2023-01"}
                        effectiveMonth={props.effectiveMonth}
                        handleEffectiveMonthChange={props.handleEffectiveMonthChange}/>
                    <span className={"small"} style={{paddingLeft: "4px"}}>{effectiveDateDisplay()}</span>
                </div>
            </div>
        </>
    );
}