import React, {useEffect} from "react";

export function TableRow(props) {
    useEffect(() => {
        props.setValidation();
    }, [])
    return (
        <tr>
            <td>
                {props.checkbox !== "false"
                    ? <label>
                        <input id={props.id}
                               name={props.description}
                               type="checkbox"
                               value={props.premium}
                               checked={props.selectedBenefits.some(x => x === props.description)}
                               className="mr-1"
                               onChange={props.onInput} 
                               onInvalid={props.onInvalid}/>
                        {props.description}
                    </label>
                    :
                    <label>
                        {props.description}
                    </label>
                }
            </td>
            <td>${props.premium}/month</td>
        </tr>
    )
}