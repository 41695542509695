import React from 'react';
import '../custom.css';

export function NavFooter(props) {

    const step = props.step;
    const lastStep = props.lastStep;

    const setCurrentStep = (step) => {
        props.onChange(step);
    }

    const prev = () => {
        setCurrentStep(
            (step <= 1)
                ? 1
                : step - 1);
    }

    const previousButton = () => {
        return (
            <button className="btn btn-danger m-3 no-print" type="button" onClick={prev}
                    hidden={step === 1 || step === lastStep} data-html2canvas-ignore="true">&lt;&nbsp;Previous</button>
        )
    }

    const nextButton = () => {
        let btn = <></>;
        const confirm = lastStep - 1;
        if (step < lastStep) {
            btn = <input className="btn btn-danger m-3 no-print" type="submit" value="Next >" data-html2canvas-ignore="true"/>
        }
        if (step === confirm) {
            btn = <input id="finalSubmit" className="btn btn-success m-3" type="submit" value="Submit" disabled={props.disabled} data-html2canvas-ignore="true"/>
        }
        if (step === lastStep) {
            btn = <></>
        }
        return (btn);
    }

    return (
        <section>
            <div className='text-right'>
                {previousButton()}
                {nextButton()}
            </div>
        </section>
    )
}