import React from "react";

export function DisenrollmentTableRow(props) {
    return (
        <tr>
            <td>
                {props.checkbox !== "false"
                    ? <label className={"mb-0"}>
                        <input id={props.description}
                               name={props.description}
                               type="checkbox"
                               value={props.id}
                               checked={props.selectedBenefits.includes(props.id.toString())}
                               className="mr-1"
                               onChange={props.onInput}/>
                        {props.description}
                    </label>
                    :
                    <label>
                        {props.description}
                    </label>
                }
            </td>
        </tr>
    )
}