import React from "react";

export function PaymentOptions(props) {
    return (
        <section className={"print " + ((props.display) ? '' : 'hidden')} id="paymentOptions">
            <div className="row mb-2">
                <div className="col">
                    <h5 className="brand-color-red mb-3">Section 2 | Payment Options</h5>
                    <hr/>
                    <p>Select a premium payment option <sup className="required">*</sup></p>
                    <div className="row mt-4">
                        <div className="col">
                            <div className="form-check mb-2">
                                <label className="form-check-label" htmlFor="paymentFacility">
                                    <input className="form-check-input"
                                           onChange={props.handlePremiumPaymentMethodIdChange}
                                           type="radio"
                                           name="paymentOptions"
                                           id="paymentFacility" 
                                           value="1"
                                           checked={props.premiumPaymentMethodId === "1"}
                                           required="required"/>
                                    Coordinate my payment through my facility</label>
                            </div>
                            <div className="form-check mb-1">
                                <label className="form-check-label" htmlFor="paymentBill">
                                    <input className="form-check-input"
                                           onChange={props.handlePremiumPaymentMethodIdChange}
                                           type="radio" 
                                           name="paymentOptions"
                                           id="paymentBill" 
                                           value="2"
                                           checked={props.premiumPaymentMethodId === "2"}
                                           required="required"/>
                                    Get a bill</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1 bill-select">
                        <div className="col">
                            <label htmlFor="mailingAddressIsFacilityOrPoa">Where should we send the member's bill?
                                <select id="mailingAddressIsFacilityOrPoa" 
                                        name="mailingAddressIsFacilityOrPoa" 
                                        value={props.mailingAddressIsFacilityOrPoa} 
                                        onChange={props.handleMailingAddressIsFacilityOrPoaChange}
                                        className="form-control" 
                                        required="required" disabled={props.premiumPaymentMethodId !== "2"}>
                                    <option value="">&nbsp;</option>
                                    <option value="poa">Authorized representative</option>
                                    <option value="facilityId">Facility</option>
                                </select></label>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}