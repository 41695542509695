import React from 'react';
import PropTypes from 'prop-types';

function EffectiveDate({minEffectiveMonth, effectiveMonth, handleEffectiveMonthChange, children}) {
    return (
        <div className="row mb-2 mt-4">
            <div className="col">
                <label className="semi-bold" htmlFor="effectiveMonth">Desired Effective Month</label>
            </div>
            <div className="col">
                <input type="month" 
                       id="effectiveMonth" 
                       name="effectiveMonth"
                       value={effectiveMonth}
                       onChange={handleEffectiveMonthChange}
                       className="form-control"
                       min={minEffectiveMonth} 
                       required="required"/>
            </div>
        </div>
    );
}

EffectiveDate.propTypes = {
    effectiveMonth: PropTypes.string.isRequired,
    minEffectiveMonth: PropTypes.string.isRequired,
    handleEffectiveMonthChange: PropTypes.func.isRequired,
}
export default EffectiveDate;