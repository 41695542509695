import React, {useCallback, useEffect, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import PrintApplication from "./PrintApplication";
import {Spinner} from "reactstrap";

export const PrintComponent = (props) => {
    const componentRef = useRef(null);
    const onBeforeGetContentResolve = useRef(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('initial text');

    const handleAfterPrint = useCallback(() => {
        console.log("onAfterPrint called");
    }, []);

    const handleBeforePrint = useCallback(() => {
        console.log("onBeforePrint called");
    }, []);

    const handleOnBeforeGetContent = useCallback(() => {
        setLoading(true);
        setMessage('Loading form ...');

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;
            setTimeout(() => {
                setLoading(false);
                setMessage('New text loaded.');
                resolve();
            }, 2000);
        });
    }, [setLoading, setMessage]);

    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "Document Title",
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true
    })

    useEffect(() => {
        if (
            message === 'New text loaded.' &&
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, message]);

    return (
        <div>
            <button className="btn btn-danger w-100"
                    type="button"
                    onClick={handlePrint}>
                {loading 
                    ? <span className={"indicator"}><Spinner size={"sm"}/></span>
                    : <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-printer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                            <path
                            d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                        </svg>
                        &nbsp;&nbsp;Print / Save
                        </span>
                }
            </button>
            <PrintApplication ref={componentRef} {...props}/>
        </div>
    );
}
