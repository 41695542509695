import React from 'react';
import {Container, Navbar, NavbarBrand} from 'reactstrap';
import {Link} from 'react-router-dom';
import '../custom.css';
import logo from '../assets/Complete-Care-Logo.png';

export function NavMenu(props) {

    const activeCrumb = (step, id) => {
        return step === id ? 'breadcrumb-item active' : 'breadcrumb-item';
    }
    
    return (
        <header>
            <Navbar className="ng-white border-bottom box-shadow mb-3 header-container" light>
                <Container>
                    <NavbarBrand tag={Link} to="/" className="logo">
                        <img src={logo} alt=""/>
                    </NavbarBrand>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className={activeCrumb(props.step, 1)} aria-current="page">Application</li>
                            <li className={activeCrumb(props.step, 2)} aria-current="page">Coverage</li>
                            <li className={activeCrumb(props.step, 3)} aria-current="page">Signature</li>
                            <li className={activeCrumb(props.step, 4)} aria-current="page">Confirmation</li>
                        </ol>
                    </nav>
                </Container>
            </Navbar>
        </header>
    );
}
