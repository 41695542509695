import React, {useEffect} from "react";
import PropTypes from "prop-types";

function OverrideReason({
                            itemList, 
                            selectedItem, 
                            handleOverrideReasonChange, 
                            handleOverrideReasonOtherChange,
                            overrideReasonOther}) {
    const otherReasonEnabled = selectedItem === '5';
    
    useEffect(() => {
        document.getElementById("overrideReasonId")
            .addEventListener("change", (ev) => {
                if (ev.target.value === '5') {
                    setTimeout(() => {
                        document.getElementById("overrideReasonOther").focus();
                    }, 250)
                }
            });
    }, []);
    return <>
        <div className="row mb-2 mt-4">
            <div className="col">
                <label className="semi-bold" htmlFor="overrideReasonId">Override Reason</label>
            </div>
            <div className="col">
                <select id={"overrideReasonId"}
                        name={"overrideReasonId"}
                        value={selectedItem}
                        onChange={handleOverrideReasonChange}
                        className={"form-control option-sm"}
                        required="required">
                    <option value={''}></option>
                    {itemList.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
            </div>
        </div>
        <div className={"row mt-3"}>
            <div className={"col-sm-12"}>
                <input id={"overrideReasonOther"}
                       name={"overrideReasonOther"}
                       className={"form-control"}
                       placeholder={"Please add text for Other here"}
                       disabled={!otherReasonEnabled}
                       onChange={handleOverrideReasonOtherChange}
                       value={otherReasonEnabled ? overrideReasonOther : ''}
                       required="required"/>
            </div>
        </div>
    </>
}

OverrideReason.propTypes = {
    itemList: PropTypes.array,
    selectedItem: PropTypes.string,
    handleOverrideReasonChange: PropTypes.func,
    handleOverrideReasonOtherChange: PropTypes.func,
}
export default OverrideReason;